import React, { useState, useEffect, version } from "react";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";
import "./index.css";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Spinner,
} from "reactstrap";
import {
  getNewOrderResultById,
  getOldOrderResultById,
} from "../../service/orderResultService";
import UnknownIcon from "./../../assets/icons/close-filled.svg";
import PartialIcon from "./../../assets/icons/partial-icon.svg";
import { useParams } from "react-router-dom";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Modal, ModalContent } from "./Modelcontent";
import { capitalizeFirstLetter } from "./utils";
import checknew from "./images/Check-icon.svg";
import infoo from "./images/info.svg";
import ZingChart from "zingchart-react";
import axios from "axios";
import X from "./images/twitter x.svg";
import camara from "./images/camera.svg";
import nopic from "./images/no-photo.png";
import snap from "./svglogo/snapchat.svg";
import profileimage from "./images/profileimage.png";
import amazon from "./svglogo/amazon.svg";
import apple from "./svglogo/apple2.svg";
import disney from "./svglogo/disneyplus.svg";
import facebook from "./svglogo/facebook.svg";
import Google from "./svglogo/go.svg";
import instagram from "./svglogo/instagram.svg";
import microsoft from "./svglogo/microsoft.svg";
import samsung from "./svglogo/samsung.svg";
import spotify from "./svglogo/spotify.svg";
import skype from "./svglogo/skype.svg";
import paypal from "./svglogo/paypal.svg";
import pinterest from "./svglogo/pinterest.svg";
import telegram from "./svglogo/telegram.svg";
import whatsapp from "./svglogo/whatsapp.svg";
import yahoo from "./svglogo/yahoo.svg";
import { info } from "toastr";
import nologo from "./svglogo/Nologo.svg";

const SearchResultDetails = () => {
  //OLD UI JS
  const [open, setOpen] = useState("1");
  const { id } = useParams();
  const [orderData, setOrderData] = useState([]);
  const [backUpdata, setBackUpdata] = useState([]);
  const [loading, setLoading] = useState(false);

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  //  useEffect(async () => {
  //   setLoading(true);
  //   const resp = await getOldOrderResultById(id);
  //   if (resp.code == 200) {
  // setBackUpdata(resp.data[0]);
  //     setOrderData(resp.data[0]);
  //   } else {
  //     setLoading(false);
  //     //result.criminal
  //   }
  //   },[id])

  /// new ui function start here
  let matchCount = 0;
  const [linkedinSlider, setLinkedinSlider] = useState([]);
  const [facebookSlider, setFacebookSlider] = useState([]);
  const [instagramSlider, setInstagramSlider] = useState([]);

  const [loaderRes, setLoderRes] = useState(true);
  const [first_name_result, setfirst_name_result] = useState(false);
  const [last_name_result, setlast_name_result] = useState(false);
  const [middle_name_result, setmiddle_name_result] = useState(false);
  const [city_result, setcity_result] = useState(false);
  const [state_result, setstate_result] = useState(false);
  const [year_of_birth_result, setage_result] = useState(false);
  const [phone_details, setphone_details] = useState(false);
  const [email_details, setemail_details] = useState(false);
  const [mrg_details, setmrg_details] = useState(false);
  const [locationPage, setLocation] = useState([]);
  const [logosPhone, setLogosPhone] = useState([]);
  const [logosEmail, setLogosEmail] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [newMatch, setNewMatch] = useState([]);
  const [MainPageReg, setMainPageReg] = useState([]);
  const [newMatchPhone, setNewMatchPhone] = useState([]);

  const [isOpen, setIsopen] = useState(false);
  const [modelDetails, setModelDetails] = useState([]);
  console.log("mainData", modelDetails && modelDetails.offenses);

  const [chartConfigP, setChartConfigP] = useState(null);
  const [chartConfigE, setChartConfigE] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [isButtonOpenSocial, setIsButtonOpenSocial] = useState(true);
  const [OpenPreviousLocations, setIsPreviousLocations] = useState(true);
  const [OpenPhoneVerification, setIsPhoneVerification] = useState(true);
  const [Openemailverification, setIsEmailVerification] = useState(true);
  const [OpeneCriminalHistory, setIsCriminalHistory] = useState(true);
  const [Version, setVersion] = useState(null);
  const [id_verified, setid_verified] = useState(false);
  const [nodata, setNoData] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showMoreEmails, setShowMoreEmails] = useState(false);

  let personalDetails =
    mainData && mainData.results && mainData.results.id_verification_result;
  for (const key in personalDetails) {
    if (
      personalDetails.hasOwnProperty(key) &&
      personalDetails[key] === "Match"
    ) {
      matchCount++;
    }
  }
  // main api called here
  useEffect(async () => {
    setLoading(true);
    setLoderRes(true);
    const resp = await getNewOrderResultById(id);

    if (resp.code == 200) {
      if (resp.version == "Gen2") {
        setVersion("Gen2");
        setMainPageReg(resp.data);
        setMainData(resp.data);
        setLoading(false);
      } else if (resp.version == "Gen1") {
        setOrderData(resp.data);
        setVersion(resp.version);
        setLoading(false);
      }
    } else {
      setLoading(false);

      //result.criminal
    }
  }, [id]);
  // console.log("this is result of order", mainData);

  // Disable browser button (Nehal vasoya)
  useEffect(() => {
    const unblock = () => {
      window.history.pushState(null, null, "/Home");
    };
    window.onpopstate = () => {
      unblock();
    };
    return () => {
      window.onpopstate = null;
    };
  }, []);

  // for Slider (Parth Roka)
  const NextArrowButton = (props) => (
    <button {...props} className="owl-next">
      <i className="fa fa-chevron-right"></i>
    </button>
  );
  const PrevArrowButton = (props) => (
    <button {...props} className="owl-prev">
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  // this was for mug shots photo

  const settings = {
    infinite: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,

    autoplaySpeed: 2000,
    dots: false,
    nextArrow: <NextArrowButton />,
    prevArrow: <PrevArrowButton />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // for zingchar
  useEffect(() => {
    const timeout = setTimeout(() => {
      const seriesValue = logosPhone?.risk_score; // Example value
      let fontColor;

      if (seriesValue >= 0 && seriesValue <= 30) {
        fontColor = "#34A853"; // Green for Low
      } else if (seriesValue > 30 && seriesValue <= 70) {
        fontColor = "#FABC04"; // Yellow for Medium
      } else if (seriesValue > 70 && seriesValue <= 100) {
        fontColor = "#E94735"; // Red for High
      }

      setChartConfigP({
        type: "gauge",
        globals: {
          fontSize: 20,
        },
        height: 110,
        width: 110,
        plot: {
          size: "100%",
          valueBox: {
            placement: "center",
            text: "%v", //default
            fontSize: 12,
            // rules: [
            //   {
            //     rule: '%v >= 8',
            //     text: '%v<br>EXCELLENT'
            //   },
            //   {
            //     rule: '%v < 8 && %v > 6',
            //     text: '%v<br>Good'
            //   },
            //   {
            //     rule: '%v < 6 && %v > 4',
            //     text: '%v<br>Fair'
            //   },
            //   {
            //     rule: '%v <  4',
            //     text: '%v<br>Bad'
            //   }
            // ]
          },
        },
        tooltip: {
          borderRadius: 5,
        },
        scaleR: {
          aperture: 180,
          minValue: 0,
          maxValue: 100,
          step: 1,
          center: {
            visible: false,
          },
          tick: {
            visible: false,
          },
          item: {
            visible: false,
            offsetR: 1,
            rules: [
              {
                rule: "",
                offsetX: 0,
              },
            ],
          },

          labels: ["0", "30", "70", "100"],
          ring: {
            size: 10,
            gradient: true,
            fillType: "linear",
            gradientColors: "#34A853 #FABC04 #E94735", // Updated gradient colors
            gradientStops: "0.0 0.3 0.7 1", // Gradient stops for Low, Medium, High
            fillAngle: "360",
            // rules: [{
            //   rule: '%v <= 2',
            //   backgroundColor: '#FF6600 #E44040'
            // },
            // {
            //   rule: '%v > 2 && %v <= 4',
            //   backgroundColor: '#E44040 #FBBC04'
            // },
            // {
            //   rule: '%v > 4 && %v <= 6',
            //   backgroundColor: '#FBBC04 #FF6600'
            // },
            // {
            //   rule: '%v > 6 && %v < 8',
            //   backgroundColor: '#FF6600'
            // },
            // {
            //   rule: '%v >= 8',
            //   backgroundColor: '#E44040'
            // }
            // ]
          },
        },
        refresh: {
          type: "feed",
          transport: "js",
          url: "feed()",
          interval: 1500,
          resetTimeout: 1000,
        },
        series: [
          {
            height: 120,
            width: 120,
            size: "",
            values: [logosPhone?.risk_score], // starting value
            backgroundColor: "black",
            indicator: [5, 8, 8, 1, 0.6],
            animation: {
              effect: 9,
              method: 1,
              sequence: 4,
              speed: 900,
            },
            valueBox: {
              fontColor: fontColor,
            },
          },
        ],
      });
    }, 1000);

    return () => clearTimeout(timeout);
  }, [logosPhone]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const seriesValue = logosEmail?.risk_score; // Example value
      let fontColor;

      // Determine font color based on value ranges
      if (seriesValue >= 0 && seriesValue <= 30) {
        fontColor = "#34A853"; // Green for Low
      } else if (seriesValue > 30 && seriesValue <= 70) {
        fontColor = "#FABC04"; // Yellow for Medium
      } else if (seriesValue > 70 && seriesValue <= 100) {
        fontColor = "#E94735"; // Red for High
      }

      setChartConfigE({
        type: "gauge",
        globals: {
          fontSize: 20,
        },
        height: 110,
        width: 110,
        plot: {
          size: "100%",
          valueBox: {
            placement: "center",
            text: "%v", //default
            fontSize: 15,
            // rules: [
            //   {
            //     rule: '%v >= 8',
            //     text: '%v<br>EXCELLENT'
            //   },
            //   {
            //     rule: '%v < 8 && %v > 6',
            //     text: '%v<br>Good'
            //   },
            //   {
            //     rule: '%v < 6 && %v > 4',
            //     text: '%v<br>Fair'
            //   },
            //   {
            //     rule: '%v <  4',
            //     text: '%v<br>Bad'
            //   }
            // ]
          },
        },
        tooltip: {
          borderRadius: 5,
        },
        scaleR: {
          aperture: 180,
          minValue: 0,
          maxValue: 100,
          step: 1,
          center: {
            visible: false,
          },
          tick: {
            visible: false,
          },
          item: {
            visible: false,
            offsetR: 1,
            rules: [
              {
                rule: "",
                offsetX: 0,
              },
            ],
          },
          labels: ["0", "30", "70", "100"],
          ring: {
            size: 10,
            gradient: true,
            fillType: "linear",
            gradientColors: "#34A853 #FABC04 #E94735", // Updated gradient colors
            gradientStops: "0.0 0.3 0.7 1", // Gradient stops for Low, Medium, High
            fillAngle: "360",
            // rules: [{
            //   rule: '%v <= 2',
            //   backgroundColor: '#FF6600 #E44040'
            // },
            // {
            //   rule: '%v > 2 && %v <= 4',
            //   backgroundColor: '#E44040 #FBBC04'
            // },
            // {
            //   rule: '%v > 4 && %v <= 6',
            //   backgroundColor: '#FBBC04 #FF6600'
            // },
            // {
            //   rule: '%v > 6 && %v < 8',
            //   backgroundColor: '#FF6600'
            // },
            // {
            //   rule: '%v >= 8',
            //   backgroundColor: '#E44040'
            // }
            // ]
          },
        },
        refresh: {
          type: "feed",
          transport: "js",
          url: "feed()",
          interval: 1500,
          resetTimeout: 1000,
        },
        series: [
          {
            height: 120,
            width: 120,
            size: "",
            values: [logosEmail?.risk_score], // starting value
            backgroundColor: "black",
            indicator: [5, 8, 8, 1, 0.6],
            animation: {
              effect: 9,
              method: 1,
              sequence: 4,
              speed: 900,
            },
            valueBox: {
              fontColor: fontColor,
            },
          },
        ],
        // plot: {
        //   valueBox: {
        //     fontColor: 'red' // Set the color for the series values
        //   }
        // }
      });
    }, 1000);

    return () => clearTimeout(timeout);
  }, [logosEmail]);

  const showModal = () =>
    modelDetails && modelDetails.photo ? setIsopen((prev) => !prev) : "";
  let modeldetails =
    modelDetails && modelDetails?.charges?.length > 0
      ? modelDetails && modelDetails?.charges[0]
      : "";

  let showing =
    mainData &&
    mainData?.results?.id_verification_result &&
    typeof mainData?.results?.id_verification_result === "object"
      ? Object.keys(mainData?.results?.id_verification_result).length === 0
        ? false
        : true
      : false;
  console.log("showing", showing);
  let PhoneType =
    mainData && mainData?.results?.phone_type
      ? mainData?.results?.phone_type
      : "";
  let firstresult =
    first_name_result == true
      ? mainData?.results?.id_verification_result.first_name_result
      : "";
  let middleresult =
    middle_name_result == true
      ? mainData?.results?.id_verification_result.middle_name_result
      : "";
  let lastresult =
    last_name_result == true
      ? mainData?.results?.id_verification_result.last_name_result
      : "";
  let stateresult =
    state_result == true
      ? mainData?.results?.id_verification_result.state_result
      : "";
  let cityresult =
    city_result == true
      ? mainData?.results?.id_verification_result.city_result
      : "";
  let ageresult =
    year_of_birth_result == true
      ? mainData?.results?.id_verification_result?.year_of_birth_result
      : "";
  let mrgresult =
    mrg_details == true
      ? mainData?.results?.id_verification_result?.mrg_details
      : "";
  function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - dob.getFullYear();
    // Adjust age if the birthday hasn't occurred yet this year
    if (
      currentDate.getMonth() < dob.getMonth() ||
      (currentDate.getMonth() === dob.getMonth() &&
        currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }
    return age;
  }

  // for Email section logos (Parth Roka)
  useEffect(() => {
    if (email_details === true) {
      const fetchLogos = async () => {
        const logos = await Promise.all(
          logosEmail?.registered_accounts.map(async (account) => {
            try {
              // Check if a static logo exists for the account
              const lowerCaseAccount = account.toLowerCase();
              if (logoMapping[lowerCaseAccount]) {
                return {
                  name: account,
                  logoUrl: logoMapping[lowerCaseAccount],
                }; // Use static logo
              }

              // Fetch from Clearbit API if no static logo found
              const response = await axios.get(
                `https://autocomplete.clearbit.com/v1/companies/suggest?query=${account}`
              );
              const logoUrl = response.data[0]?.logo || null; // Fallback to null if no logo found
              return { name: account, logoUrl };
            } catch (error) {
              console.error(`Error fetching logo for ${account}:`, error);

              // Handle CORS or Network errors specifically
              if (
                error.message.includes("CORS") ||
                error.message.includes("Network Error")
              ) {
                return { name: account, logoUrl: nologo }; // Static fallback logo
              }

              return { name: account, logoUrl: nologo }; // Fallback for other errors
            }
          })
        );

        // Update accounts with the logos (or null if not found)
        const updatedAccounts = newMatch.map((account, index) => ({
          ...account,
          logos: logos ? logos[index] : [],
        }));

        // Sort updated accounts (if needed, based on accountInfo.url)
        const sortedData = updatedAccounts.slice().sort((a, b) => {
          if (a.accountInfo && b.accountInfo) {
            if (a.accountInfo.url && !b.accountInfo.url) {
              return -1;
            } else if (!a.accountInfo.url && b.accountInfo.url) {
              return 1;
            }
          } else if (a.accountInfo && !b.accountInfo) {
            return -1;
          } else if (!a.accountInfo && b.accountInfo) {
            return 1;
          }
          return 0;
        });

        setNewMatch(sortedData); // Update state with sorted accounts
      };

      fetchLogos();
    }
  }, [logosEmail]); // Only run when logosEmail changes

  const logoMapping = {
    snapchat: snap,
    google: Google,
    instagram: instagram,
    amazon: amazon,
    apple: apple,
    disneyplus: disney,
    facebook: facebook,
    paypal: paypal,
    pinterest: pinterest,
    telegram: telegram,
    whatsapp: whatsapp,
    twitter: X, // Twitter is now X
    yahoo: yahoo,
    microsoft: microsoft,
    skype: skype,
    spotify: spotify,
    samsung: samsung,
  };

  // for Phone section logos (Parth Roka)
  useEffect(() => {
    if (phone_details === true) {
      const fetchLogos = async () => {
        const logos = await Promise.all(
          logosPhone?.registered_accounts.map(async (account) => {
            try {
              const lowerCaseAccount = account.toLowerCase();

              // Check if the logo is available locally
              if (logoMapping[lowerCaseAccount]) {
                return {
                  name: account,
                  logoUrl: logoMapping[lowerCaseAccount],
                };
              }

              // Fetch from Clearbit API if not found locally
              const response = await axios.get(
                `https://autocomplete.clearbit.com/v1/companies/suggest?query=${account}`
              );

              const logoUrl = response.data[0]?.logo || null;
              return { name: account, logoUrl };
            } catch (error) {
              console.error(`Error fetching logo for ${account}:`, error);

              // Handle CORS or network-related issues
              if (
                error.response?.status === 0 ||
                error.message.includes("Network Error")
              ) {
                return {
                  name: account,
                  logoUrl: nologo, // Fallback to static SVG
                };
              }

              // Handle other errors with a fallback logo
              return {
                name: account,
                logoUrl: nologo,
              };
            }
          })
        );

        const updatedAccounts = logosPhone?.registered_accounts.map(
          (account, index) => ({
            ...account,
            logos: logos[index],
          })
        );

        setNewMatchPhone(updatedAccounts);
      };

      fetchLogos();
    }
  }, [logosPhone]);

  useEffect(() => {
    if (MainPageReg) {
      setLoderRes(true);
      if (
        MainPageReg &&
        MainPageReg.results &&
        MainPageReg.results.id_verification_result
      ) {
        const {
          first_name_result,
          last_name_result,
          city_result,
          state_result,
          middle_name_result,
          year_of_birth_result,
          id_verified,
          marital_status,
        } = MainPageReg.results.id_verification_result;
        const { phone_details, email_details } =
          MainPageReg.results.id_social_result;
        const matchingAccountsInfo = email_details
          ? email_details.registered_accounts.map((account) => {
              let accountInfo = null;
              if (email_details && email_details.hasOwnProperty(account)) {
                accountInfo = email_details && email_details[account];
              }
              return { account, accountInfo };
            })
          : [];
        const matchingAccountsInfoPhone = phone_details
          ? phone_details.registered_accounts.map((account) => {
              let accountInfo = null;

              if (phone_details && phone_details.hasOwnProperty(account)) {
                accountInfo = phone_details && phone_details[account];
              }
              return { account, accountInfo };
            })
          : [];
        setLinkedinSlider(email_details ? email_details?.linkedin?.photo : "");
        setFacebookSlider(email_details ? email_details?.facebook?.photo : "");
        setInstagramSlider(
          email_details ? email_details?.instagram?.photo : ""
        );
        setLogosPhone(phone_details ? phone_details : []);
        setLogosEmail(email_details ? email_details : []);
        setNewMatch(matchingAccountsInfo ? matchingAccountsInfo : []);
        setNewMatchPhone(
          matchingAccountsInfoPhone ? matchingAccountsInfoPhone : []
        );
        if (id_verified) {
          setid_verified(true);
        } else {
          setid_verified(false);
        }
        if (first_name_result) {
          setfirst_name_result(true);
        } else {
          setfirst_name_result(false);
        }
        if (last_name_result) {
          setlast_name_result(true);
        } else {
          setlast_name_result(false);
        }
        if (city_result) {
          setcity_result(true);
        } else {
          setcity_result(false);
        }
        if (state_result) {
          setstate_result(true);
        } else {
          setstate_result(false);
        }
        if (middle_name_result) {
          setmiddle_name_result(true);
        } else {
          setmiddle_name_result(false);
        }
        if (year_of_birth_result) {
          setage_result(true);
        } else {
          setage_result(false);
        }
        if (phone_details) {
          setphone_details(true);
        } else {
          setphone_details(false);
        }
        if (email_details) {
          setemail_details(true);
        } else {
          setemail_details(false);
        }
        if (marital_status) {
          setmrg_details(true);
        } else {
          setmrg_details(false);
        }
      }
      // setMainData(mainData ? mainData : []);

      setLocation(mainData ? mainData?.enhanced_info?.locations : []);
    } else {
      setLoderRes(false);
      setMainData([]);
      setLocation([]);
    }
  }, [mainData]);

  function formatPhoneNumber(phoneNumber) {
    // Format the phone number according to the desired format
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

    const formattedPhoneNumber = cleanedPhoneNumber.startsWith("1")
      ? `+${cleanedPhoneNumber}`
      : `+1${cleanedPhoneNumber}`;

    const countryCode = formattedPhoneNumber.slice(0, 2);
    const areaCode = formattedPhoneNumber.slice(2, 5);
    const firstThree = formattedPhoneNumber.slice(5, 8);
    const lastFour = formattedPhoneNumber.slice(8);

    // Construct the formatted phone number with one space after the country code
    const formattedNumberWithSpaces = `${countryCode}.${areaCode}.${firstThree}.${lastFour}`;

    return formattedNumberWithSpaces;
  }
  const hasNoLogosEmail = () => {
    return (
      (logosEmail && logosEmail?.registered_accounts?.length === 0) ||
      undefined ||
      (Array.isArray(logosEmail) && logosEmail.length === 0)
    );
  };
  const hasNoIdVerificationResult = () => {
    return !(
      mainData &&
      mainData.results &&
      mainData.results.id_verification_result !== null &&
      mainData.results.id_verification_result !== undefined &&
      Object.keys(mainData.results.id_verification_result).length > 0
    );
  };
  // locationPage && locationPage.length > 0 && locationPage[0].state === "" && locationPage[0].city === "" && locationPage[0].county === ""
  const isLocationEmpty = () => {
    return (
      locationPage &&
      locationPage.length > 0 &&
      locationPage[0].state === "" &&
      locationPage[0].city === "" &&
      locationPage[0].county === ""
    );
  };
  const hasNoLogosPhone = () => {
    return (
      (logosPhone && logosPhone?.registered_accounts?.length === 0) ||
      (Array.isArray(logosPhone) && logosPhone.length === 0)
    );
  };
  const hasNoCrimeSearchResult = () => {
    return (
      mainData?.results && mainData?.results.crime_search_result.length === 0
    );
  };
  useEffect(() => {
    if (
      hasNoLogosEmail() &&
      hasNoIdVerificationResult() &&
      isLocationEmpty() &&
      hasNoLogosPhone() &&
      hasNoCrimeSearchResult()
    ) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [logosEmail, mainData, locationPage, logosPhone]);
  // Determine the numbers to show based on the state

  const phoneNumbers =
    mainData?.enhanced_info?.potential_phone_numbers?.split(", ") || [];
  // Determine the numbers to show based on the state
  const displayedNumbers = showMore ? phoneNumbers : phoneNumbers.slice(0, 3);

  const emails = mainData?.enhanced_info?.potential_emails?.split(", ") || [];

  const displayedEmails = showMoreEmails ? emails : emails.slice(0, 3);


  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full viewport height
          width: "100%", // Full width
          position: "absolute", // Overlaying the entire page if needed
        }}
      >
        {loading ? <Spinner /> : ""}
      </div>
      {!loading ? (
        Version &&
        Version == "Gen2" && (
          <>
            <React.Fragment>
              <div className="page-content">
                <MetaTags>
                  <title> Search Result Details | BrightCheck</title>
                </MetaTags>
                <Container fluid>
                  {/* Render Breadcrumb */}
                  {/* <Breadcrumbs
            title="Dashboards"
            breadcrumbItem="Order"
          /> */}
                  <div
                    className="d-block"
                    style={{ zIndex: 10, position: "relative" }}
                  >
                    <Link
                      to="/search-results"
                      className="text-gray text-hover-gray"
                    >
                      <i className="bx bx-arrow-back"></i> Back to Search
                      results
                    </Link>
                  </div>
                </Container>
                <Container fluid></Container>
                <main className="main-or-bg py-4">
                  <section className="">
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-md-2 col-2">
                          <div className="header-icon ms-lg-3">
                            <a
                              onClick={() => {
                                navigate("/Home");
                                localStorage.removeItem("scan_ids");
                              }}
                            >
                              {/* <img src={homeicon} style={{ cursor: "pointer" }} /> */}
                            </a>
                          </div>
                        </div>
                        <div className="col-md-8 col-8">
                          <a>
                            <div className="logo-login text-center">
                              {/* <img src={ProLogo} /> */}
                            </div>
                          </a>
                        </div>
                        <div className="col-md-2 col-2">
                          {/* <LogoutButton /> */}
                        </div>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="container">
                      <div className="row d-flex justify-content-center">
                        {/* COMMENT 1 FOR SIDEBAR */}

                        <div className="col-md-12 mt-3">
                          <div className="main-info-card main-info-nopad">
                            <div className="personal-info-area1">
                              <div className="container ">
                                <div className="mt290">
                                  {" "}
                                  {/* {mainData?.enhanced_info?.phone_number} */}
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="profile-info ms-4">
                                        <h4 className="theme-color mt-3 fw-600 fs-35">
                                          {mainData &&
                                            mainData?.enhanced_info
                                              ?.first_name}{" "}
                                          {mainData &&
                                            mainData?.enhanced_info?.last_name}
                                        </h4>
                                        {mainData?.enhanced_info?.phone_number && (
  <div className="d-flex">
    <p className="fw-normal mt-3 fs-22">
      Phone Number:
      <span className="fw-bold theme-color">
      {`+1-${(
                                    mainData?.enhanced_info
                                      ?.phone_number &&
                                    mainData.enhanced_info.phone_number
                                  )
                                    .toString()
                                    .replace(
                                      /(\d{3})(\d{3})(\d{4})/,
                                      "$1-$2-$3"
                                    )}`}
      </span>
    </p>
    <div className="verif-card-purple-phone">
      <img className="w15 me-2" src={checknew} />
      Verified
    </div>
  </div>
)}

                                        {mainData.enhanced_info.email
                                          ? mainData?.enhanced_info
                                              ?.email && (
                                              <div className="d-flex align-items-center">
                                                <p className="fw-normal mt-3 fs-22 mb-0">
                                                  Email:{" "}
                                                  <span className="fw-bold theme-color">
                                                    {mainData.enhanced_info
                                                      .email ? (
                                                      <span className="theme-color">
                                                        {
                                                          mainData.enhanced_info
                                                            .email
                                                        }
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                </p>
                                                <div className="verif-card-purplephone2 ms-2">
                                                  <img
                                                    className="w15 me-1"
                                                    src={checknew}
                                                    alt="Verified"
                                                  />
                                                  Verified
                                                </div>
                                              </div>
                                            )
                                          : ""}
                                      </div>
                                    </div>

                                    <div className="col-lg-6">
                                      <div className="profile-phot-div-new">
                                        <img
                                          src={nopic}
                                          alt=""
                                          className="profile-img"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12"></div>
                                  </div>
                                </div>
                              </div>
                              {/* COMMENT 2  the phto section for admin commented for */}
                              {/* {!nodata && (
                                <div className="accordion-item accordian-theme rounded-8 mb-4 col-md-12">
                                  <h2
                                    className="accordion-header"
                                    id="headingThree"
                                  >
                                    <div className="check-info justify-content-between">
                                      <div className="row align-items-center">
                                        {/* Left Side: Details */}
                              {/* <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                          <h3 className="mb-2">
                                            No Information Found.
                                          </h3>
                                          <p className="mb-2 text-gray-light-new">
                                            Something here about redoing the
                                            search results.
                                          </p>
                                                                        </div> */}

                              {/* Right Side: Image/Slider */}
                              {/* <div className="col-md-6">
                                          <div className="card image-card-new">
                                            <div className="card-body">
                                              <div className="slider-area p-0">
                                                <div className="carousel-wrap">
                                                  {linkedinSlider?.length ||
                                                  facebookSlider?.length ||
                                                  instagramSlider?.length ||
                                                  photos?.length ? (
                                                    <Slider {...settings}>
                                                      {(photos || []).map(
                                                        (i, index) => (
                                                          <div
                                                            className="item"
                                                            key={index}
                                                          >
                                                            <div className="slider-card-person">
                                                              <img
                                                                className="slide-img"
                                                                src={i.photo}
                                                                alt="photo"
                                                              />
                                                              <div className="upload-div-slider">
                                                                <a>
                                                                  <img
                                                                    src={camara}
                                                                    alt="camera"
                                                                  />{" "}
                                                                  Mug Shot
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                      {(
                                                        allSocialPhotos || []
                                                      ).map((i, index) => (
                                                        <div
                                                          className="item"
                                                          key={index}
                                                        >
                                                          <div className="slider-card-person">
                                                            <img
                                                              className="slide-img"
                                                              src={i.photo}
                                                              alt="photo"
                                                            />
                                                            <div className="upload-div-slider">
                                                              <a>
                                                                <img
                                                                  src={camara}
                                                                  alt="camera"
                                                                />{" "}
                                                                {i.objectName}
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ))}
                                                    </Slider>
                                                  ) : (
                                                    <div className="item">
                                                      <div className="slider-card-person">
                                                        <img
                                                          className="slide-img"
                                                          src={nopic}
                                                          alt="No photo available"
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div> */}
                              {/* </div>
                                    </div>
                                  </h2>
                                </div>
                              )}  */}

                              {mainData && mainData.enhanced_info !== null ? (
                                <div className="accordion-item accordian-theme rounded-8 mb-4">
                                  <h2
                                    className="accordion-header"
                                    id="headingThree"
                                  >
                                    <button
                                      className={`accordion-button align-items-center mt-0 ${
                                        isButtonOpenSocial ? "" : "collapsed"
                                      }`}
                                      type="button"
                                      onClick={() => {
                                        setIsButtonOpenSocial(
                                          !isButtonOpenSocial
                                        );
                                      }}
                                    >
                                      <div className="">
                                        <div className="check-info d-flex justify-content-between">
                                          {/* <i className="color-orange fas fa-check-circle"></i> */}
                                          <h3 className="mb-0 ps-0">
                                            {" "}
                                            Personal Info
                                          </h3>
                                          <div class="container1">
                                            <div class="tooltip ms-1">
                                              <img
                                                className="d-block"
                                                src={infoo}
                                              />
                                              <span class="tooltiptext">
                                                <div className="px-3 py-2 text-start">
                                                  <h5>What is this?</h5>
                                                  <p className="m-0 p-0">
                                                    This checks the person’s
                                                    name, age, marital status,
                                                    and other personal identify
                                                    details.
                                                  </p>
                                                </div>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <p className="text-gray-light-new lh0 mt-1">
                                          {matchCount}{" "}
                                          {matchCount && matchCount > 1
                                            ? "Details"
                                            : "Detail"}{" "}
                                          Verified
                                        </p>
                                        {/* <p className="text-gray-light-new lh0 mt-3">
                                    {matchCount} Details Verified
                                  </p> */}
                                      </div>
                                    </button>
                                  </h2>
                                  {/* {isButtonOpenSocial ?
                    <hr className="accordian-hr"></hr> : ""} */}

                                  <div
                                    id="collapseThree"
                                    className={`accordion-collapse personal-info-area1 ${
                                      isButtonOpenSocial
                                        ? "collapse show"
                                        : "collapse"
                                    }`}
                                  >
                                    <div className="row">
                                      {mainData &&
                                      mainData?.enhanced_info?.first_name ? (
                                        <div className="col-md-4 mb-4 ">
                                          <div className="card detailcard-new">
                                            <label className="light-text">
                                              First Name
                                            </label>
                                            <h5 className="dark-purple-color">
                                              {loaderRes == true ? (
                                                mainData &&
                                                mainData?.enhanced_info
                                                  ?.first_name ? (
                                                  mainData?.enhanced_info?.first_name
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                  mainData?.enhanced_info?.first_name.slice(
                                                    1
                                                  )
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                <Skeleton count={1} />
                                              )}
                                            </h5>

                                            {/* <h5 className="dark-purple-color">{loaderRes == true ? mainData && mainData?.enhanced_info?.first_name ? mainData?.enhanced_info?.first_name : "" : <Skeleton count={1} />}</h5> */}
                                            {/* <div className={ firstresult == "Match" ? "verif-card-purple" : firstresult == "No Match" ? "notverif-card-dark" : firstresult == "Partial Match" ? "notverif-card-dark" : ""}>
                                    <img className="w15 me-1" src={checknew} />{ firstresult == "Match" ? "Verified" : firstresult == "No Match" ? "Not Verified" :firstresult == "Partial Match" ? "Not Verified" : ""}</div> */}
                                            {firstresult !== "" &&
                                            firstresult == "Match" ? (
                                              <div className="verif-card-purple">
                                                <img
                                                  className="w15 me-1"
                                                  src={checknew}
                                                />
                                                Verified
                                              </div>
                                            ) : showing === true ? (
                                              <div className="notverif-card-dark">
                                                <i className="fa fa-minus me-1"></i>
                                                Not Verified
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {mainData &&
                                      mainData?.enhanced_info?.middle_name ? (
                                        <div className="col-md-4 mb-4 ">
                                          <div className="card detailcard-new">
                                            <label className="light-text">
                                              Middle Name
                                            </label>
                                            <h5 className="dark-purple-color">
                                              {loaderRes == true ? (
                                                mainData &&
                                                mainData?.enhanced_info
                                                  ?.middle_name ? (
                                                  mainData?.enhanced_info?.middle_name
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                  mainData?.enhanced_info?.middle_name.slice(
                                                    1
                                                  )
                                                ) : (
                                                  "-"
                                                )
                                              ) : (
                                                <Skeleton count={1} />
                                              )}
                                            </h5>
                                            {/* <div className={ middleresult == "Match" ? "verif-card-purple" : middleresult == "No Match" ? "notverif-card-dark" : ""}><img className="w15 me-1" src={checknew} />{ middleresult == "Match" ? "Verified" : middleresult == "No Match" ? "Not Verified" : ""}</div> */}
                                            {middleresult == "Match" ? (
                                              <div className="verif-card-purple">
                                                <img
                                                  className="w15 me-1"
                                                  src={checknew}
                                                />
                                                Verified
                                              </div>
                                            ) : showing === true ? (
                                              <div className="notverif-card-dark">
                                                <i className="fa fa-minus me-1"></i>
                                                Not Verified
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {mainData &&
                                      mainData?.enhanced_info?.last_name ? (
                                        <div className="col-md-4 mb-4 ">
                                          <div className="card detailcard-new">
                                            <label className="light-text">
                                              Last Name
                                            </label>
                                            <h5 className="dark-purple-color">
                                              {loaderRes == true ? (
                                                mainData &&
                                                mainData?.enhanced_info
                                                  ?.last_name ? (
                                                  mainData?.enhanced_info?.last_name
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                  mainData?.enhanced_info?.last_name.slice(
                                                    1
                                                  )
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                <Skeleton count={1} />
                                              )}
                                            </h5>
                                            {/* <div className={ lastresult == "Match" ? "verif-card-purple" : lastresult == "No Match" ? "notverif-card-dark" : ""}><img className="w15 me-1" src={checknew} />{ lastresult == "Match" ? "Verified" : lastresult == "No Match" ? "Not Verified" : ""}</div> */}
                                            {lastresult == "Match" ? (
                                              <div className="verif-card-purple">
                                                <img
                                                  className="w15 me-1"
                                                  src={checknew}
                                                />
                                                Verified
                                              </div>
                                            ) : showing === true ? (
                                              <div className="notverif-card-dark">
                                                <i className="fa fa-minus me-1" />
                                                Not Verified
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {locationPage &&
                                      locationPage.length > 0 ? (
                                        locationPage[0].city ? (
                                          <div className="col-md-4 mb-4 ">
                                            <div className="card detailcard-new">
                                              <label className="light-text">
                                                City
                                              </label>
                                              <h5 className="dark-purple-color">
                                                {loaderRes == true ? (
                                                  locationPage &&
                                                  locationPage.length > 0 ? (
                                                    locationPage[0].city ? (
                                                      locationPage[0].city
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                      locationPage[0].city.slice(
                                                        1
                                                      )
                                                    ) : (
                                                      "-"
                                                    )
                                                  ) : (
                                                    "-"
                                                  )
                                                ) : (
                                                  <Skeleton count={1} />
                                                )}
                                              </h5>
                                              {/* <div className={ cityresult == "Match" ? "verif-card-purple" : cityresult == "No Match" ? "notverif-card-dark" : ""}><img className="w15 me-1" src={checknew} />{ cityresult == "Match" ? "Verified" : cityresult == "No Match" ? "Not Verified" : ""}</div> */}

                                              {cityresult == "Match" ? (
                                                <div className="verif-card-purple">
                                                  <img
                                                    className="w15 me-1"
                                                    src={checknew}
                                                  />
                                                  Verified
                                                </div>
                                              ) : showing === true ? (
                                                <div className="notverif-card-dark">
                                                  <i className="fa fa-minus me-1" />
                                                  Not Verified
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                      {locationPage &&
                                      locationPage.length > 0 ? (
                                        locationPage[0].state ? (
                                          <div className="col-md-4 mb-4 ">
                                            <div className="card detailcard-new">
                                              <label className="light-text">
                                                State
                                              </label>
                                              <h5 className="dark-purple-color">
                                                {loaderRes == true ? (
                                                  locationPage &&
                                                  locationPage.length > 0 ? (
                                                    locationPage[0].state ? (
                                                      locationPage[0].state
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                      locationPage[0].state.slice(
                                                        1
                                                      )
                                                    ) : (
                                                      "-"
                                                    )
                                                  ) : (
                                                    "-"
                                                  )
                                                ) : (
                                                  <Skeleton count={1} />
                                                )}
                                              </h5>
                                              {/* <div className={ stateresult == "Match" ? "verif-card-purple" : stateresult == "No Match" ? "notverif-card-dark" : ""}><img className="w15 me-1" src={checknew} />{ stateresult == "Match" ? "Verified" : stateresult == "No Match" ? "Not Verified" : ""}</div> */}
                                              {stateresult == "Match" ? (
                                                <div className="verif-card-purple">
                                                  <img
                                                    className="w15 me-1"
                                                    src={checknew}
                                                  />
                                                  Verified
                                                </div>
                                              ) : showing === true ? (
                                                <div className="notverif-card-dark">
                                                  <i className="fa fa-minus me-1" />
                                                  Not Verified
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                      {(mainData &&
                                        mainData?.enhanced_info
                                          ?.date_of_birth) ||
                                      (mainData &&
                                        mainData?.enhanced_info?.age) ? (
                                        <div className="col-md-4 mb-4">
                                          <div className="card detailcard-new">
                                            <label className="light-text">
                                              Age
                                            </label>
                                            <h5 className="dark-purple-color d-flex w-100 justify-content-between">
                                              {loaderRes == true ? (
                                                mainData &&
                                                mainData?.enhanced_info
                                                  ?.date_of_birth ? (
                                                  calculateAge(
                                                    mainData?.enhanced_info
                                                      ?.date_of_birth
                                                  )
                                                ) : mainData &&
                                                  mainData?.enhanced_info
                                                    ?.age ? (
                                                  mainData?.enhanced_info?.age
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                <Skeleton count={1} />
                                              )}
                                              <h5 className="dark-purple-color mb-0">
                                                {/* {loaderRes === true ? (moment(mainData?.enhanced_info?.date_of_birth).format('MM/DD/YYYY')) : ""} */}
                                                {/* {loaderRes === false && <Skeleton count={1} />} */}
                                                {loaderRes == true ? (
                                                  mainData &&
                                                  mainData?.enhanced_info
                                                    ?.date_of_birth ? (
                                                    moment(
                                                      mainData?.enhanced_info
                                                        ?.date_of_birth
                                                    ).format("MM/DD/YYYY")
                                                  ) : (
                                                    ""
                                                  )
                                                ) : (
                                                  <Skeleton count={1} />
                                                )}
                                              </h5>
                                            </h5>
                                            {/* <div className={ ageresult == "Match" ? "verif-card-purple" : ageresult == "No Match" ? "notverif-card-dark" :ageresult == "Partial Match" ? "notverif-card-dark" : ""}>
                                    <img className="w15 me-1" src={checknew} />{ ageresult == "Match" ? "Verified" : ageresult == "No Match" ? "Not Verified" :ageresult == "Partial Match" ? "Not Verified" : ""}</div> */}
                                            {ageresult == "Match" ? (
                                              <div className="verif-card-purple">
                                                <img
                                                  className="w15 me-1"
                                                  src={checknew}
                                                />
                                                Verified
                                              </div>
                                            ) : showing === true ? (
                                              <div className="notverif-card-dark">
                                                <i className="fa fa-minus me-1"></i>
                                                Not Verified
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {mainData &&
                                      mainData?.results?.id_verification_result
                                        .marital_status ? (
                                        <div className="col-md-4 mb-4">
                                          <div className="card detailcard-new">
                                            <label className="light-text">
                                              Marriage Status
                                            </label>
                                            <h5 className="dark-purple-color">
                                              {loaderRes == true ? (
                                                mainData?.results
                                                  ?.id_verification_result
                                                  .marital_status ? (
                                                  mainData?.results
                                                    ?.id_verification_result
                                                    .marital_status ==
                                                  "No Data" ? (
                                                    "Unknown"
                                                  ) : (
                                                    mainData?.results
                                                      ?.id_verification_result
                                                      .marital_status
                                                  )
                                                ) : (
                                                  "-"
                                                )
                                              ) : (
                                                <Skeleton count={1} />
                                              )}
                                            </h5>
                                            {/* <div className="verif-card-purple"><img className="w15 me-1" src={checknew} />Verified</div> */}

                                            {/* {mrgresult == "Match" ? <div className="verif-card-purple"><img className="w15 me-1" src={checknew} />Verified</div> :
                                    <div className="notverif-card-dark"><i className="fa fa-minus me-1"></i>Not Verified</div>} */}
                                            {loaderRes == true ? (
                                              mainData?.results
                                                ?.id_verification_result
                                                .marital_status ? (
                                                mainData?.results
                                                  ?.id_verification_result
                                                  .marital_status ==
                                                "No Data" ? (
                                                  <div className="notverif-card-dark">
                                                    <i className="fa fa-minus me-1"></i>
                                                    Not Verified
                                                  </div>
                                                ) : (
                                                  <div className="verif-card-purple">
                                                    <img
                                                      className="w15 me-1"
                                                      src={checknew}
                                                    />
                                                    Verified
                                                  </div>
                                                )
                                              ) : (
                                                ""
                                              )
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {mainData &&
                                        mainData?.enhanced_info
                                          ?.potential_phone_numbers && (
                                          <div className="col-md-4 mb-4">
                                            <div className="card detailcard-new">
                                              <p className="fw-normal mt-3 fs-22">
                                                Potential Phone Numbers:
                                                <h4 className="fw-bold theme-color">
                                                  {" "}
                                                  {displayedNumbers.map(
                                                    (phoneNumber, index) => (
                                                      <div key={index}>
                                                        <p className="theme-color">
                                                          {`+1-${phoneNumber
                                                            .toString()
                                                            .replace(
                                                              /(\d{3})(\d{3})(\d{4})/,
                                                              "$1-$2-$3"
                                                            )}`}
                                                        </p>
                                                      </div>
                                                    )
                                                  )}{" "}
                                                </h4>
                                              </p>
                                              {phoneNumbers.length > 3 && (
                                                <p
                                                  onClick={() =>
                                                    setShowMore(!showMore)
                                                  }
                                                  className="btn p-0"
                                                  style={{
                                                    color: "rgb(131, 56, 236)",
                                                  }}
                                                >
                                                  {showMore
                                                    ? "Show Less"
                                                    : "Show More"}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        )}

                                      {mainData &&
                                        mainData?.enhanced_info
                                          ?.potential_emails && (
                                          <div className="col-md-4 mb-4">
                                            <div className="card detailcard-new">
                                              <p className="fw-normal mt-3 fs-22">
                                                Potential Emails:
                                                <h4 className="fw-bold theme-color">
                                                  {" "}
                                                  {displayedEmails.map(
                                                    (email, index) => (
                                                      <div key={index}>
                                                        <p className="theme-color">
                                                          {email}
                                                        </p>
                                                      </div>
                                                    )
                                                  )}
                                                </h4>
                                              </p>
                                              {console.log("mainData.enhanced_infopotential_emailslength",mainData.enhanced_info
                                                .potential_emails.length)}
                                              {emails.length >
                                                3 && (
                                                <p
                                                  onClick={() =>
                                                    setShowMoreEmails(
                                                      !showMoreEmails
                                                    )
                                                  }
                                                  className="btn p-0"
                                                  style={{
                                                    color: "rgb(131, 56, 236)",
                                                  }}
                                                >
                                                  {showMoreEmails
                                                    ? "Show Less"
                                                    : "Show More"}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="accordion-item accordian-theme rounded-8 mb-4">
                                  <h2
                                    className="accordion-header"
                                    id="headingThree"
                                  >
                                    <button
                                      className="accordion-button align-items-center mt-0 collapsed cross-btn-main-new"
                                      type="button"
                                    >
                                      <div className="">
                                        <div className="check-info d-flex justify-content-between">
                                          {/* <i className="color-orange fas fa-check-circle"></i> */}
                                          <h3 className="mb-0">
                                            {" "}
                                            Personal Info
                                          </h3>
                                          <div class="container1">
                                            <div class="tooltip ms-1">
                                              <img
                                                className="d-block"
                                                src={infoo}
                                              />
                                              <span class="tooltiptext">
                                                <div className="px-3 py-2 text-start">
                                                  <h5>What is this?</h5>
                                                  <p className="m-0 p-0">
                                                    This checks the person’s
                                                    name, age, marital status,
                                                    and other personal identify
                                                    details.
                                                  </p>
                                                </div>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <p className="text-gray-light-new lh0 mt-1">
                                          0 Detail Verified
                                        </p>
                                      </div>
                                    </button>
                                  </h2>
                                </div>
                              )}
                              {locationPage &&
                              locationPage.length > 0 &&
                              (locationPage[0].state != "" ||
                                locationPage[0].city != "" ||
                                locationPage[0].county != "") ? (
                                <div className="accordion-item accordian-theme rounded-8 mb-4">
                                  <h2
                                    className="accordion-header"
                                    id="headingThree"
                                  >
                                    <button
                                      className={`accordion-button align-items-center mt-0 ${
                                        OpenPreviousLocations ? "" : "collapsed"
                                      }`}
                                      type="button"
                                      onClick={() => {
                                        setIsPreviousLocations(
                                          !OpenPreviousLocations
                                        );
                                      }}
                                    >
                                      <div className="">
                                        <div className="check-info d-flex justify-content-between">
                                          {/* <i className="color-orange fas fa-check-circle"></i> */}
                                          <h3 className="mb-0 ps-0">
                                            Previous Locations
                                          </h3>
                                          <div class="container1">
                                            <div class="tooltip ms-1">
                                              <img
                                                className="d-block"
                                                src={infoo}
                                              />
                                              <span class="tooltiptext">
                                                <div className="px-3 py-2 text-start">
                                                  <h5>What is this?</h5>
                                                  <p className="m-0 p-0">
                                                    This confirms the places the
                                                    person has lived.{" "}
                                                  </p>
                                                </div>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <p className="text-gray-light-new lh0 mt-1">
                                          {locationPage.length}{" "}
                                          {locationPage &&
                                          locationPage.length > 1
                                            ? "Locations"
                                            : "Location"}{" "}
                                          Verified
                                        </p>
                                      </div>
                                    </button>
                                  </h2>
                                  {/* {isButtonOpenSocial ?
                    <hr className="accordian-hr"></hr> : ""} */}

                                  <div
                                    id="collapseThree"
                                    className={`accordion-collapse personal-info-area1 ${
                                      OpenPreviousLocations
                                        ? "collapse show"
                                        : "collapse"
                                    }`}
                                  >
                                    <div className="row">
                                      {locationPage && locationPage.length > 0
                                        ? locationPage.map((i, index) => {
                                            return (
                                              <div className="col-md-12 mb-4 ">
                                                <div className="card detailcard-new fld-row">
                                                  <div className="w33 bor-right-1">
                                                    <label className="light-text">
                                                      City
                                                    </label>
                                                    <h5 className="dark-purple-color">
                                                      {capitalizeFirstLetter(
                                                        i.city
                                                      )}
                                                    </h5>
                                                  </div>
                                                  <div className="w33 bor-right-1 ms-3">
                                                    <label className="light-text">
                                                      State
                                                    </label>
                                                    <h5 className="dark-purple-color">
                                                      {capitalizeFirstLetter(
                                                        i.state
                                                      )}
                                                    </h5>
                                                  </div>
                                                  <div className="w33 ms-3">
                                                    <label className="light-text">
                                                      County
                                                    </label>
                                                    <h5 className="dark-purple-color">
                                                      {capitalizeFirstLetter(
                                                        i.county
                                                      )}
                                                    </h5>
                                                  </div>
                                                  <div className="verif-card-purple">
                                                    <img
                                                      className="w15 me-1"
                                                      src={checknew}
                                                    />
                                                    Verified
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })
                                        : ""}
                                      {/* <div className="col-md-12 mb-4 ">
                            <div className="card detailcard-new fld-row">
                                <div className="w33 bor-right-1">
                                <label className="light-text">City</label>
                                <h5 className="dark-purple-color">Walnut Creek</h5>
                                </div>
                                <div className="w33 bor-right-1 ms-3">
                                <label className="light-text">State</label>
                                <h5 className="dark-purple-color">CA</h5>
                                </div>
                                <div className="w33 ms-3">
                                <label className="light-text">County</label>
                                <h5 className="dark-purple-color">Contra Costa County</h5>
                                </div>
                                <div className="verif-card-purple"><img className="w15 me-1" src={checknew} />Verified</div>
                            </div>
                        </div> */}
                                      {/* <div className="col-md-12 mb-4 ">
                            <div className="card detailcard-new fld-row">
                                <div className="w33 bor-right-1">
                                <label className="light-text">City</label>
                                <h5 className="dark-purple-color">Breckenridge</h5>
                                </div>
                                <div className="w33 bor-right-1 ms-3">
                                <label className="light-text">State</label>
                                <h5 className="dark-purple-color">CO</h5>
                                </div>
                                <div className="w33 ms-3">
                                <label className="light-text">County</label>
                                <h5 className="dark-purple-color">Summit County</h5>
                                </div>
                                <div className="verif-card-purple"><img className="w15 me-1" src={checknew} />Verified</div>
                            </div>
                        </div> */}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="accordion-item accordian-theme rounded-8 mb-4">
                                  <h2
                                    className="accordion-header"
                                    id="headingThree"
                                  >
                                    <button
                                      className="accordion-button align-items-center mt-0 collapsed cross-btn-main-new"
                                      type="button"
                                      onClick={() => {
                                        setIsPreviousLocations(
                                          !OpenPreviousLocations
                                        );
                                      }}
                                    >
                                      <div className="">
                                        <div className="check-info d-flex justify-content-between">
                                          {/* <i className="color-orange fas fa-check-circle"></i> */}
                                          <h3 className="mb-0 ps-0">
                                            Previous Locations
                                          </h3>
                                          <div class="container1">
                                            <div class="tooltip ms-1">
                                              <img
                                                className="d-block"
                                                src={infoo}
                                              />
                                              <span class="tooltiptext">
                                                <div className="px-3 py-2 text-start">
                                                  <h5>What is this?</h5>
                                                  <p className="m-0 p-0">
                                                    This confirms the places the
                                                    person has lived.{" "}
                                                  </p>
                                                </div>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <p className="text-gray-light-new lh0 mt-1 ">
                                          0 Location Verified
                                        </p>
                                      </div>
                                    </button>
                                  </h2>
                                </div>
                              )}
                              {mainData &&
                              logosPhone &&
                              logosPhone?.registered_accounts?.length > 0 ? (
                                <>
                                  <div className="accordion-item accordian-theme rounded-8 mb-4">
                                    <h2
                                      className="accordion-header"
                                      id="headingThree"
                                    >
                                      <button
                                        className={`accordion-button align-items-center mt-0 ${
                                          OpenPhoneVerification
                                            ? ""
                                            : "collapsed"
                                        }`}
                                        type="button"
                                        onClick={() => {
                                          setIsPhoneVerification(
                                            !OpenPhoneVerification
                                          );
                                        }}
                                      >
                                        <div className="">
                                          <div className="check-info d-flex justify-content-between">
                                            {/* <i className="color-orange fas fa-check-circle"></i> */}
                                            <h3 className="mb-0 ps-0">
                                              Phone Verification
                                            </h3>
                                            <div class="container1">
                                              <div class="tooltip ms-1">
                                                <img
                                                  className="d-block"
                                                  src={infoo}
                                                />
                                                <span class="tooltiptext">
                                                  <div className="px-3 py-2 text-start">
                                                    <h5>What is this?</h5>
                                                    <p className="m-0 p-0">
                                                      This confirms if the phone
                                                      number is associated with
                                                      a social media account.
                                                    </p>
                                                  </div>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          {logosPhone &&
                                          logosPhone?.registered_accounts
                                            ?.length >= 0 ? (
                                            <p className="text-gray-light-new lh0 mt-1 ">
                                              {
                                                logosPhone?.registered_accounts
                                                  .length
                                              }{" "}
                                              {logosPhone?.registered_accounts
                                                .length &&
                                              logosPhone?.registered_accounts
                                                .length > 1
                                                ? "Social Sites"
                                                : "Social Site"}{" "}
                                              Verified
                                              {/* {logosPhone?.registered_accounts.length === 0 ? "0" : logosPhone?.registered_accounts.length} Social Sites Verified */}
                                            </p>
                                          ) : (
                                            <p className="text-gray-light-new lh0 mt-1">
                                              0 Social Site Verified
                                            </p>
                                          )}
                                          {/* <p className="text-gray-light-new lh0 mt-3">7 Social Sites Verified</p> */}
                                        </div>
                                      </button>
                                    </h2>
                                    {/* {isButtonOpenSocial ?
                    <hr className="accordian-hr"></hr> : ""} */}

                                    <div
                                      id="collapseThree"
                                      className={`accordion-collapse personal-info-area1 ${
                                        OpenPhoneVerification
                                          ? "collapse show"
                                          : "collapse"
                                      }`}
                                    >
                                      <div className="row">
                                        <div className="col-md-4 mb-4 mt-2">
                                          <div className="card phone-card">
                                            <div className="detailcard-new border-0">
                                              {/* <label className="light-puple-bg-new">VOICE OVER IP</label> */}
                                              <label className="light-puple-bg-new">
                                                {PhoneType}
                                              </label>
                                              <h5 className="dark-purple-color mt-5">
                                                {mainData &&
                                                mainData.phone_number
                                                  ? formatPhoneNumber(
                                                      mainData.phone_number
                                                    )
                                                  : ""}
                                              </h5>
                                              <div class=" tooltip w-auto m-0 p-0 d-block">
                                                <div className="fraud-rating-card d-flex">
                                                  <div className="text-div-main">
                                                    {logosPhone !== null && (
                                                      <div>
                                                        {logosPhone?.risk_score >=
                                                          0 &&
                                                          logosPhone?.risk_score <=
                                                            30 && (
                                                            <h4
                                                              style={{
                                                                color:
                                                                  "#34A853",
                                                              }}
                                                            >
                                                              Low
                                                            </h4>
                                                          )}
                                                        {logosPhone?.risk_score >
                                                          30 &&
                                                          logosPhone?.risk_score <=
                                                            70 && (
                                                            <h4
                                                              style={{
                                                                color:
                                                                  "#FABC04",
                                                              }}
                                                            >
                                                              Medium
                                                            </h4>
                                                          )}
                                                        {logosPhone?.risk_score >
                                                          70 &&
                                                          logosPhone?.risk_score <=
                                                            100 && (
                                                            <h4
                                                              style={{
                                                                color:
                                                                  "#E94735",
                                                              }}
                                                            >
                                                              High
                                                            </h4>
                                                          )}
                                                      </div>
                                                    )}
                                                    <p className="light-puple-bg w72">
                                                      Fraud Rating
                                                    </p>
                                                  </div>
                                                  <div>
                                                    {chartConfigP && (
                                                      <ZingChart
                                                        data={chartConfigP}
                                                        id="myChart"
                                                        className="rating-main"
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                                <span class="tooltiptext rating-tooltiptext">
                                                  <div className="px-3 py-2 text-start">
                                                    {/* <h5>What is this?</h5> */}
                                                    {logosPhone !== null && (
                                                      <div>
                                                        {logosPhone?.risk_score ==
                                                          0 && (
                                                          <p className="m-0 p-0">
                                                            There's a low
                                                            likelihood of
                                                            fraudulent activity
                                                            connected to the
                                                            email or phone
                                                            number.
                                                          </p>
                                                        )}
                                                        {logosPhone?.risk_score >=
                                                          1 &&
                                                          logosPhone?.risk_score <=
                                                            3 && (
                                                            <p className="m-0 p-0">
                                                              There's a moderate
                                                              likelihood of
                                                              fraudulent
                                                              activity
                                                              associated with
                                                              the email or phone
                                                              number.
                                                            </p>
                                                          )}
                                                        {logosPhone?.risk_score >=
                                                          4 && (
                                                          <p className="m-0 p-0">
                                                            There's a high
                                                            likelihood of
                                                            fraudulent activity
                                                            linked to the email
                                                            or phone number.
                                                          </p>
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                </span>
                                              </div>
                                              <div className="verif-card-purple">
                                                <img
                                                  className="w15 me-1"
                                                  src={checknew}
                                                />
                                                Verified
                                              </div>
                                            </div>
                                            {/* <div class="check-info d-flex justify-content-between px-3 py-3 light-perpurre-box">
                                <p class="mb-0 order-flex">View Profile</p>
                                
                                <div class="">
                                    <a class="color-right-light right-arrrow-icon">
                                        <i class="fas fa-chevron-right"></i>
                                    </a>
                            </div>
                            </div> */}
                                          </div>
                                        </div>
                                        {loaderRes == true ? (
                                          phone_details == true ? (
                                            newMatchPhone &&
                                            newMatchPhone.map(
                                              (account, index) => {
                                                return (
                                                  <div
                                                    className="col-md-4 mb-4 mt-2"
                                                    key={index}
                                                  >
                                                    <div className="card phone-card">
                                                      <div className="detailcard-new border-0">
                                                        {/* <img src={account.logos && account.logos ? account.logos?.logoUrl : ""} alt={account.logos?.logoUrl} className="phone-ver-icons-new" /> */}
                                                        {
                                                          account.logos &&
                                                          account.logos
                                                            .logoUrl ? ( // Render the <img> tag only if logoUrl exists
                                                            <img
                                                              src={
                                                                account.logos.logoUrl.includes(
                                                                  "google"
                                                                ) // Check for Twitter specifically
                                                                  ? google // Use the local SVG for X (Twitter)
                                                                  : account
                                                                      .logos
                                                                      .logoUrl // Use the provided logo URL
                                                              }
                                                              alt="Logo" // Provide a meaningful alt text
                                                              className="phone-ver-icons-new"
                                                              onError={(e) => {
                                                                e.target.onerror =
                                                                  null; // Prevent infinite loop if fallback image also fails
                                                                e.target.style.display =
                                                                  "none"; // Hide the image element if it fails to load
                                                              }}
                                                            />
                                                          ) : null /* Do not render <img> if logoUrl is not present */
                                                        }
                                                        <h5 className="dark-purple-color mt-5">
                                                          {/* {capitalizeFirstLetter(
                                                            account.account
                                                              ? account.account ==
                                                                "twitter"
                                                                ? "x"
                                                                : account.account
                                                              : account.account
                                                          )} */}
                                                        </h5>
                                                        <div className="verif-card-purple">
                                                          <img
                                                            className="w15 me-1"
                                                            src={checknew}
                                                          />
                                                          Verified
                                                        </div>
                                                      </div>
                                                      {account.accountInfo &&
                                                        account.accountInfo
                                                          .url && (
                                                          <div class="check-info d-flex justify-content-between px-3 py-3 light-perpurre-box">
                                                            <p class="mb-0 order-flex">
                                                              View Profile
                                                            </p>
                                                            <div class="">
                                                              <a
                                                                href={
                                                                  account.accountInfo &&
                                                                  account
                                                                    .accountInfo
                                                                    .url
                                                                    ? account
                                                                        .accountInfo
                                                                        .url
                                                                    : ""
                                                                }
                                                                target="_blank"
                                                              >
                                                                <i class="fas fa-chevron-right"></i>
                                                              </a>
                                                            </div>
                                                          </div>
                                                        )}
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )
                                          ) : (
                                            ""
                                          )
                                        ) : (
                                          <Skeleton count={1} />
                                        )}
                                        {/* <div className="col-md-4 mb-4 mt-2">
                            <div className="card">
                            <div className="detailcard-new border-0">
                            <img src={linkedin} alt="" className="phone-ver-icons-new"/>
                                <h5 className="dark-purple-color mt-5">Linked In</h5>
                                <div className="verif-card-purple"><img className="w15 me-1" src={checknew} />Verified</div>
                            </div>
                                <div class="check-info d-flex justify-content-between px-3 py-3 light-perpurre-box">
                                <p class="mb-0 order-flex">View Profile</p>
                                <div class="">
                                    <a class="color-right-light right-arrrow-icon">
                                        <i class="fas fa-chevron-right"></i>
                                    </a>
                            </div>
                            </div>
                            </div>                                                        
                        </div>  */}
                                        {/* <div className="col-md-4 mb-4 mt-2">
                            <div className="card">
                            <div className="detailcard-new border-0">
                            <img src={instagram} alt="" className="phone-ver-icons-new"/>
                                <h5 className="dark-purple-color mt-5">Instagram</h5>
                                <div className="verif-card-purple"><img className="w15 me-1" src={checknew} />Verified</div>
                            </div>
                                <div class="check-info d-flex justify-content-between px-3 py-3 light-perpurre-box">
                                <p class="mb-0 order-flex">View Profile</p>
                                <div class="">
                                    <a class="color-right-light right-arrrow-icon">
                                        <i class="fas fa-chevron-right"></i>
                                    </a>
                            </div>
                            </div>
                            </div>                                                        
                        </div>  */}
                                        {/* <div className="col-md-4 mb-4 mt-2">
                            <div className="card">
                            <div className="detailcard-new border-0">
                            <img src={Spotify} alt="" className="phone-ver-icons-new"/>
                                <h5 className="dark-purple-color mt-5">Spotify</h5>
                                <div className="verif-card-purple"><img className="w15 me-1" src={checknew} />Verified</div>
                            </div>
                                <div class="check-info d-flex justify-content-between px-3 py-3 light-perpurre-box">
                                <p class="mb-0 order-flex">View Profile</p>
                                <div class="">
                                    <a class="color-right-light right-arrrow-icon">
                                        <i class="fas fa-chevron-right"></i>
                                    </a>
                            </div>
                            </div>
                            </div>                                                        
                        </div>  */}
                                        {/* <div className="col-md-4 mb-4 mt-2">
                            <div className="card">
                            <div className="detailcard-new border-0">
                            <img src={Google} alt="" className="phone-ver-icons-new"/>
                                <h5 className="dark-purple-color mt-5">Google</h5>
                                <div className="verif-card-purple"><img className="w15 me-1" src={checknew} />Verified</div>
                            </div>
                                <div class="check-info d-flex justify-content-between px-3 py-3 light-perpurre-box">
                                <p class="mb-0 order-flex">View Profile</p>
                                <div class="">
                                    <a class="color-right-light right-arrrow-icon">
                                        <i class="fas fa-chevron-right"></i>
                                    </a>
                            </div>
                            </div>
                            </div>                                                        
                        </div>  */}
                                        {/* <div className="col-md-4 mb-4 mt-2">
                            <div className="card">
                            <div className="detailcard-new border-0">
                            <img src={X} alt="" className="phone-ver-icons-new"/>
                                <h5 className="dark-purple-color mt-5">X</h5>
                                <div className="verif-card-purple"><img className="w15 me-1" src={checknew} />Verified</div>
                            </div>
                                <div class="check-info d-flex justify-content-between px-3 py-3 light-perpurre-box">
                                <p class="mb-0 order-flex">View Profile</p>
                                <div class="">
                                    <a class="color-right-light right-arrrow-icon">
                                        <i class="fas fa-chevron-right"></i>
                                    </a>
                            </div>
                            </div>
                            </div>                                                        
                        </div>  */}
                                        {/* <div className="col-md-4 mb-4 mt-2">
                            <div className="card">
                            <div className="detailcard-new border-0">
                            <img src={TikTok} alt="" className="phone-ver-icons-new"/>
                                <h5 className="dark-purple-color mt-5">TikTok</h5>
                                <div className="verif-card-purple"><img className="w15 me-1" src={checknew} />Verified</div>
                            </div>
                                <div class="check-info d-flex justify-content-between px-3 py-3 light-perpurre-box">
                                <p class="mb-0 order-flex">View Profile</p>
                                <div class="">
                                    <a class="color-right-light right-arrrow-icon">
                                        <i class="fas fa-chevron-right"></i>
                                    </a>
                            </div>
                            </div>
                            </div>
                                                        
                        </div>  */}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="accordion-item accordian-theme rounded-8 mb-4">
                                    <h2
                                      className="accordion-header"
                                      id="headingThree"
                                    >
                                      <button
                                        className="accordion-button align-items-center mt-0 collapsed cross-btn-main-new"
                                        type="button"
                                        onClick={() => {
                                          setIsPhoneVerification(
                                            !OpenPhoneVerification
                                          );
                                        }}
                                      >
                                        <div className="">
                                          <div className="check-info d-flex justify-content-between">
                                            <h3 className="mb-0 ps-0">
                                              Phone Verification
                                            </h3>
                                            <div class="container1">
                                              <div class="tooltip ms-1">
                                                <img
                                                  className="d-block"
                                                  src={infoo}
                                                />
                                                <span class="tooltiptext">
                                                  <div className="px-3 py-2 text-start">
                                                    <h5>What is this?</h5>
                                                    <p className="m-0 p-0">
                                                      This confirms if the phone
                                                      number is associated with
                                                      a social media account.
                                                    </p>
                                                  </div>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <p className="text-gray-light-new lh0 mt-1">
                                            0 Social Site Verified
                                          </p>
                                        </div>
                                      </button>
                                    </h2>
                                  </div>
                                </>
                              )}
                              {mainData &&
                              logosEmail &&
                              logosEmail?.registered_accounts?.length > 0 ? (
                                <>
                                  <div className="accordion-item accordian-theme rounded-8 mb-4">
                                    <h2
                                      className="accordion-header"
                                      id="headingThree"
                                    >
                                      <button
                                        className={`accordion-button align-items-center mt-0 ${
                                          Openemailverification
                                            ? ""
                                            : "collapsed"
                                        }`}
                                        type="button"
                                        onClick={() => {
                                          setIsEmailVerification(
                                            !Openemailverification
                                          );
                                        }}
                                      >
                                        <div className="">
                                          <div className="check-info d-flex justify-content-between">
                                            {/* <i className="color-orange fas fa-check-circle"></i> */}
                                            <h3 className="mb-0">
                                              Email Verification
                                            </h3>
                                            <div class="container1">
                                              <div class="tooltip ms-1">
                                                <img
                                                  className="d-block"
                                                  src={infoo}
                                                />
                                                <span class="tooltiptext">
                                                  <div className="px-3 py-2 text-start">
                                                    <h5>What is this?</h5>
                                                    <p className="m-0 p-0">
                                                      This confirms if the email
                                                      address is associated with
                                                      a social media account.
                                                    </p>
                                                  </div>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          {logosEmail &&
                                          logosEmail?.registered_accounts
                                            ?.length >= 0 ? (
                                            <p className="text-gray-light-new lh0 mt-1 ">
                                              {
                                                logosEmail?.registered_accounts
                                                  .length
                                              }{" "}
                                              {logosEmail?.registered_accounts
                                                .length &&
                                              logosEmail?.registered_accounts
                                                .length > 1
                                                ? "Social Sites"
                                                : "Social Site"}{" "}
                                              Verified
                                              {/* {logosEmail?.registered_accounts.length === 0 ? "0" : logosEmail?.registered_accounts.length} Social Sites Verified */}
                                            </p>
                                          ) : (
                                            <p className="text-gray-light-new lh0 mt-1 ">
                                              0 Social Site Verified
                                            </p>
                                          )}
                                        </div>
                                      </button>
                                    </h2>
                                    {/* {isButtonOpenSocial ?
                    <hr className="accordian-hr"></hr> : ""} */}

                                    <div
                                      id="collapseThree"
                                      className={`accordion-collapse personal-info-area1 ${
                                        Openemailverification
                                          ? "collapse show"
                                          : "collapse"
                                      }`}
                                    >
                                      <div className="row">
                                        <div className="col-md-4 mb-4 mt-2">
                                          <div className="card email-card">
                                            <div className="detailcard-new border-0">
                                              <label className="light-puple-bg-new">
                                                EMAIL
                                              </label>
                                              <h5 className="dark-purple-color mt-5">
                                                {mainData?.results &&
                                                mainData?.email
                                                  ? mainData?.email
                                                  : ""}
                                              </h5>
                                              <div class="tooltip w-auto m-0 p-0 d-block">
                                                <div className="fraud-rating-card d-flex">
                                                  <div className="text-div-main">
                                                    {logosEmail !== null && (
                                                      <div>
                                                        {logosEmail?.risk_score >=
                                                          0 &&
                                                          logosEmail?.risk_score <=
                                                            30 && (
                                                            <h4
                                                              style={{
                                                                color:
                                                                  "#34A853", // Green
                                                              }}
                                                            >
                                                              LOW
                                                            </h4>
                                                          )}
                                                        {logosEmail?.risk_score >
                                                          30 &&
                                                          logosEmail?.risk_score <=
                                                            70 && (
                                                            <h4
                                                              style={{
                                                                color:
                                                                  "#FABC04", // Yellow
                                                              }}
                                                            >
                                                              MED
                                                            </h4>
                                                          )}
                                                        {logosEmail?.risk_score >
                                                          70 &&
                                                          logosEmail?.risk_score <=
                                                            100 && (
                                                            <h4
                                                              style={{
                                                                color:
                                                                  "#E5413E", // Red
                                                              }}
                                                            >
                                                              HIGH
                                                            </h4>
                                                          )}
                                                      </div>
                                                    )}

                                                    <p className="light-puple-bg">
                                                      Fraud Rating
                                                    </p>
                                                  </div>
                                                  <div>
                                                    {chartConfigE && (
                                                      <ZingChart
                                                        data={chartConfigE}
                                                        id="myChart1"
                                                        className="rating-main"
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                                <span class="tooltiptext rating-tooltiptext">
                                                  <div className="px-3 py-2 text-start">
                                                    {/* <h5>What is this?</h5> */}
                                                    {logosEmail &&
                                                    logosEmail
                                                      ?.registered_accounts
                                                      ?.length >= 0 ? (
                                                      <p className="text-gray-light-new lh0 mt-1 ">
                                                        {
                                                          logosEmail
                                                            .registered_accounts
                                                            .length
                                                        }{" "}
                                                        {logosEmail
                                                          .registered_accounts
                                                          .length > 1
                                                          ? "Social Sites"
                                                          : "Social Site"}{" "}
                                                        Verified{" "}
                                                        {logosEmail
                                                          .registered_accounts
                                                          .length >= 0 &&
                                                          logosEmail
                                                            .registered_accounts
                                                            .length <= 30 && (
                                                            <span
                                                              style={{
                                                                color:
                                                                  "#34A853",
                                                              }}
                                                            >
                                                              {" "}
                                                              (Low)
                                                            </span>
                                                          )}
                                                        {logosEmail
                                                          .registered_accounts
                                                          .length > 30 &&
                                                          logosEmail
                                                            .registered_accounts
                                                            .length <= 70 && (
                                                            <span
                                                              style={{
                                                                color:
                                                                  "#FABC04",
                                                              }}
                                                            >
                                                              {" "}
                                                              (Medium)
                                                            </span>
                                                          )}
                                                        {logosEmail
                                                          .registered_accounts
                                                          .length > 70 && (
                                                          <span
                                                            style={{
                                                              color: "#E94735",
                                                            }}
                                                          >
                                                            {" "}
                                                            (High)
                                                          </span>
                                                        )}
                                                      </p>
                                                    ) : (
                                                      <p className="text-gray-light-new lh0 mt-1 ">
                                                        0 Social Site Verified{" "}
                                                        <span
                                                          style={{
                                                            color: "#34A853",
                                                          }}
                                                        >
                                                          {" "}
                                                          (Low)
                                                        </span>
                                                      </p>
                                                    )}

                                                    {/* <p className="m-0 p-0">There's a high likelihood of fraudulent activity linked to the email or phone number.</p>
                              <p className="m-0 p-0">There's a moderate likelihood of fraudulent activity associated with the email or phone number.</p>
                              <p className="m-0 p-0">There's a low likelihood of fraudulent activity connected to the email or phone number.</p> */}
                                                  </div>
                                                </span>
                                              </div>
                                              <div className="verif-card-purple">
                                                <img
                                                  className="w15 me-1"
                                                  src={checknew}
                                                />
                                                Verified
                                              </div>
                                            </div>

                                            {/* <div class="check-info d-flex justify-content-between px-3 py-3 light-perpurre-box">
                                <p class="mb-0 order-flex">View Profile</p>
                                <div class="">
                                    <a class="color-right-light right-arrrow-icon">
                                        <i class="fas fa-chevron-right"></i>
                                    </a>
                            </div>
                            </div> */}
                                          </div>
                                        </div>
                                        {loaderRes == true ? (
                                          email_details == true ? (
                                            newMatch &&
                                            newMatch.map(
                                              (account, accountInfo) => {
                                                return (
                                                  <div
                                                    className="col-md-4 mb-4 mt-2"
                                                    key={account.account}
                                                  >
                                                    <div
                                                      className="card email-card"
                                                      key={account.account}
                                                    >
                                                      <div className="detailcard-new border-0">
                                                        <img
                                                          src={
                                                            account.logos &&
                                                            account.logos
                                                              ? account.logos.logoUrl.includes(
                                                                  "google"
                                                                )
                                                                ? Google
                                                                : account.logos
                                                                    .logoUrl
                                                              : ""
                                                          }
                                                          alt={
                                                            account.logos
                                                              ?.logoUrl
                                                          }
                                                          className="phone-ver-icons-new"
                                                        />
                                                        <h5 className="dark-purple-color mt-5">
                                                          {capitalizeFirstLetter(
                                                            account.account
                                                              ? account.account ==
                                                                "twitter"
                                                                ? "x"
                                                                : account.account
                                                              : account.account
                                                          )}
                                                        </h5>
                                                        {/* <img src={account.logos && account.logos ? account.logos?.logoUrl : ""} alt={account.logos?.logoUrl} className="phone-ver-icons-new" />
                                          <h5 className="dark-purple-color mt-5">{capitalizeFirstLetter(account.account)}</h5> */}
                                                        <div className="verif-card-purple">
                                                          <img
                                                            className="w15 me-1"
                                                            src={checknew}
                                                          />
                                                          Verified
                                                        </div>
                                                      </div>
                                                      {account.accountInfo &&
                                                        account.accountInfo
                                                          .url && (
                                                          <div class="check-info d-flex justify-content-between px-3 py-3 light-perpurre-box">
                                                            <p class="mb-0 order-flex">
                                                              View Profile
                                                            </p>
                                                            <div class="">
                                                              <a
                                                                href={
                                                                  account.accountInfo &&
                                                                  account
                                                                    .accountInfo
                                                                    .url
                                                                    ? account
                                                                        .accountInfo
                                                                        .url
                                                                    : ""
                                                                }
                                                                target="_blank"
                                                              >
                                                                <i class="fas fa-chevron-right"></i>
                                                              </a>
                                                            </div>
                                                          </div>
                                                        )}
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )
                                          ) : (
                                            ""
                                          )
                                        ) : (
                                          <Skeleton count={1} />
                                        )}
                                        {/* <div className="col-md-4 mb-4 mt-2">
                            <div className="card">
                            <div className="detailcard-new border-0">
                            <img src={linkedin} alt="" className="phone-ver-icons-new"/>
                                <h5 className="dark-purple-color mt-5">Linked In</h5>
                                <div className="verif-card-purple"><img className="w15 me-1" src={checknew} />Verified</div>
                            </div>
                                <div class="check-info d-flex justify-content-between px-3 py-3 light-perpurre-box">
                                <p class="mb-0 order-flex">View Profile</p>
                                <div class="">
                                    <a class="color-right-light right-arrrow-icon">
                                        <i class="fas fa-chevron-right"></i>
                                    </a>
                            </div>
                            </div>
                            </div>
                                                        
                        </div>  */}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="accordion-item accordian-theme rounded-8 mb-4">
                                    <h2
                                      className="accordion-header"
                                      id="headingThree"
                                    >
                                      <button
                                        className="accordion-button align-items-center mt-0 collapsed cross-btn-main-new"
                                        type="button"
                                        onClick={() => {
                                          setIsEmailVerification(
                                            !Openemailverification
                                          );
                                        }}
                                      >
                                        <div className="">
                                          <div className="check-info d-flex justify-content-between">
                                            {/* <i className="color-orange fas fa-check-circle"></i> */}
                                            <h3 className="mb-0 ps-0">
                                              Email Verification
                                            </h3>
                                            <div class="container1">
                                              <div class="tooltip ms-1">
                                                <img
                                                  className="d-block"
                                                  src={infoo}
                                                />
                                                <span class="tooltiptext">
                                                  <div className="px-3 py-2 text-start">
                                                    <h5>What is this?</h5>
                                                    <p className="m-0 p-0">
                                                      This confirms if the email
                                                      address is associated with
                                                      a social media account.
                                                    </p>
                                                  </div>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <p className="text-gray-light-new lh0 mt-1 ">
                                            0 Social Site Verified
                                          </p>
                                        </div>
                                      </button>
                                    </h2>{" "}
                                  </div>{" "}
                                </>
                              )}
                              {mainData?.results &&
                              mainData?.results.crime_search_result.length >
                                0 ? (
                                <>
                                  <div className="accordion-item accordian-theme rounded-8 mb-4">
                                    <h2
                                      className="accordion-header"
                                      id="headingThree"
                                    >
                                      <button
                                        className={`accordion-button align-items-center mt-0 ${
                                          OpeneCriminalHistory
                                            ? ""
                                            : "collapsed"
                                        }`}
                                        type="button"
                                        onClick={() => {
                                          setIsCriminalHistory(
                                            !OpeneCriminalHistory
                                          );
                                        }}
                                      >
                                        <div className="">
                                          <div className="check-info d-flex justify-content-between">
                                            {/* <i className="color-orange fas fa-check-circle"></i> */}
                                            <h3 className="mb-0 ps-0">
                                              Criminal History Verification
                                            </h3>
                                            <div class="container1">
                                              <div class="tooltip ms-1">
                                                <img
                                                  className="d-block"
                                                  src={infoo}
                                                />
                                                <span class="tooltiptext">
                                                  <div className="px-3 py-2 text-start">
                                                    <h5>What is this?</h5>
                                                    <p className="m-0 p-0">
                                                      This reviews the person’s
                                                      criminal record.
                                                    </p>
                                                  </div>
                                                </span>
                                              </div>
                                            </div>
                                          </div>

                                          {mainData?.results &&
                                          mainData?.results.crime_search_result
                                            .length ? (
                                            // If true, display the value of modelDetails.number_of_charges
                                            // <p>{mainData?.results && mainData?.results.crime_search_result.length} Records Verified</p>
                                            <p className="text-gray-light-new lh0 mt-1">
                                              {
                                                mainData?.results
                                                  .crime_search_result.length
                                              }{" "}
                                              {mainData?.results &&
                                              mainData?.results
                                                .crime_search_result.length > 1
                                                ? "Records"
                                                : "Record"}{" "}
                                              Verified
                                            </p>
                                          ) : (
                                            // If false, display "0"
                                            <p className="text-gray-light-new lh0 mt-1 ">
                                              0 Record Verified
                                            </p>
                                          )}
                                        </div>
                                      </button>
                                    </h2>
                                    {/* {isButtonOpenSocial ?
                    <hr className="accordian-hr"></hr> : ""} */}

                                    <div
                                      id="collapseThree"
                                      className={`accordion-collapse personal-info-area1 ${
                                        OpeneCriminalHistory
                                          ? "collapse show"
                                          : "collapse"
                                      }`}
                                    >
                                      <div className="row">
                                        {mainData?.results &&
                                        mainData?.results.crime_search_result
                                          .length > 0
                                          ? mainData?.results?.crime_search_result.map(
                                              (i, index) => (
                                                <div className="col-md-6 mb-4">
                                                  <div className="card Criminal-card-main">
                                                    <div className="detailcard-new border-0">
                                                      <div className="verif-card-purple">
                                                        <img
                                                          className="w15 me-1"
                                                          src={checknew}
                                                        />
                                                        Verified
                                                      </div>
                                                      <div className="name-content p-2">
                                                        <label>
                                                          Record Name
                                                        </label>
                                                        <h5>
                                                          {/* {i.charges &&
                                                          i.charges[0]
                                                            .charge_classification
                                                            ? i.charges[0]
                                                                ?.charge_classification
                                                                ?.primary_category
                                                            : ""} */}
                                                          {i.record_county}
                                                        </h5>
                                                        <hr className="bottom-hr" />
                                                      </div>

                                                      <div className="row">
                                                        <div className="col-lg-6">
                                                          <div className="name-content pb-4">
                                                            <label>
                                                              Record Date
                                                            </label>
                                                            <h5 className="">
                                                              {i.record_date
                                                                ? moment(
                                                                    i.record_date
                                                                  ).format(
                                                                    "MM/DD/YYYY"
                                                                  )
                                                                : i.record_date}
                                                            </h5>
                                                          </div>
                                                          <div className="name-content pb-4">
                                                            <label>
                                                              Charges Filed Date
                                                            </label>
                                                            <h5 className="">
                                                              {i.charges
                                                                ? i.charges[0]
                                                                    .charge_filed_date
                                                                  ? moment(
                                                                      i
                                                                        .charges[0]
                                                                        ?.charge_filed_date
                                                                    ).format(
                                                                      "MM/DD/YYYY"
                                                                    )
                                                                  : ""
                                                                : ""}
                                                            </h5>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                          <div className="name-content pb-4">
                                                            <label>
                                                              Case Number
                                                            </label>
                                                            <h5 className="">
                                                              {i.case_number}
                                                            </h5>
                                                          </div>

                                                          <div className="name-content pb-4">
                                                            <label>
                                                              # of Charges
                                                            </label>
                                                            <h5 className="">
                                                              {
                                                                i.number_of_charges
                                                              }
                                                            </h5>
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-12">
                                                          <div className="name-content pb-4">
                                                            <label>
                                                              Charges
                                                              Description
                                                            </label>
                                                            <h5 className="">
                                                              {i.charges &&
                                                              i.charges[0]
                                                                .offense_description
                                                                ? i.charges[0]
                                                                    ?.offense_description
                                                                : ""}{" "}
                                                            </h5>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      data-bs-toggle="offcanvas"
                                                      data-bs-target="#offcanvasRight"
                                                      aria-controls="offcanvasRight"
                                                      onClick={() =>
                                                        setModelDetails(i)
                                                      }
                                                      class="cursor-pointer-new check-info d-flex justify-content-between px-3 py-3 light-perpurre-box"
                                                    >
                                                      <p class="mb-0 order-flex">
                                                        View Full Report
                                                      </p>
                                                      <div class="">
                                                        <a
                                                          data-bs-toggle="offcanvas"
                                                          data-bs-target="#offcanvasRight"
                                                          aria-controls="offcanvasRight"
                                                          onClick={() =>
                                                            setModelDetails(i)
                                                          }
                                                          class="color-right-light right-arrrow-icon"
                                                        >
                                                          <i class="fas fa-chevron-right"></i>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )
                                          : ""}
                                        {/* <div className="col-md-6" >
                    <div className="card">
                            <div className="detailcard-new border-0">
                              <div className="verif-card-purple"><img className="w15 me-1" src={checknew} />Verified</div>
                              <div className="name-content p-2">
                                    <label>Record Name</label>
                                    <h5>CA Sex Offender Registery</h5>
                                    <hr className="bottom-hr" />
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="name-content pb-4">
                                        <label>Record Date</label>
                                        <h5 className="">7/7/1999</h5>
                                      </div>
                                      <div className="name-content pb-4">
                                        <label>Charges Filed Date</label>
                                        <h5 className="">7/7/1999</h5>
                                      </div>
                                    </div>

                                    <div className="col-lg-6">
                                      <div className="name-content pb-4">
                                        <label>Case Number</label>
                                        <h5 className="">234897623874</h5>
                                      </div>

                                      <div className="name-content pb-4">
                                        <label># of Charges</label>
                                        <h5 className="">1</h5>
                                      </div>
                                    </div>

                                    <div className="col-lg-12">
                                      <div className="name-content pb-4">
                                        <label>Charges Description</label>
                                        <h5 className="">Lewd or lascivious acts with a minor person </h5>
                                      </div>
                                    </div>
                                  </div>
                                
                            </div>
                                <div class="check-info d-flex justify-content-between px-3 py-3 light-perpurre-box">
                                <p class="mb-0 order-flex">View Profile</p>
                                <div class="">
                                    <a class="color-right-light right-arrrow-icon">
                                        <i class="fas fa-chevron-right"></i>
                                    </a>
                            </div>
                            </div>
                            </div>
                              </div> */}
                                      </div>
                                    </div>
                                  </div>{" "}
                                </>
                              ) : (
                                <>
                                  <div className="accordion-item accordian-theme rounded-8 mb-4">
                                    <h2
                                      className="accordion-header"
                                      id="headingThree"
                                    >
                                      <button
                                        className="accordion-button align-items-center mt-0 collapsed cross-btn-main-new"
                                        type="button"
                                        onClick={() => {
                                          setIsCriminalHistory(
                                            !OpeneCriminalHistory
                                          );
                                        }}
                                      >
                                        <div className="">
                                          <div className="check-info d-flex justify-content-between">
                                            {/* <i className="color-orange fas fa-check-circle"></i> */}
                                            <h3 className="mb-0 ps-0">
                                              Criminal History Verification
                                            </h3>
                                            <div class="container1">
                                              <div class="tooltip ms-1">
                                                <img
                                                  className="d-block"
                                                  src={infoo}
                                                />
                                                <span class="tooltiptext">
                                                  <div className="px-3 py-2 text-start">
                                                    <h5>What is this?</h5>
                                                    <p className="m-0 p-0">
                                                      This reviews the person’s
                                                      criminal record.
                                                    </p>
                                                  </div>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <p className="text-gray-light-new lh0 mt-1 ">
                                            0 Record Verified
                                          </p>
                                        </div>
                                      </button>
                                    </h2>
                                  </div>
                                </>
                              )}

                              {/* <div className="text-center px-lg-5 px-3">
                      <p className="px-lg-5 text-gray-light-new">
                        "BrightCheck may display inaccurate info, including
                        about people, so double-check its
                        <br /> responses. 
                        <a href="" className="text-gray-light-new">
                          Your privacy & BrightCheck Policy
                        </a>
                        "
                      </p>
                    </div> */}
                            </div>
                            {/* {mainData == "" ? (
                    <div className="personal-info-area px-4 mb-4" data-aos="fade-left" data-aos-duration="500">
                      <div className="row">
                        <div className="col-md-12">
                          <h3 className="fw-bold pinfo-h3">Personal Info {id_verified == true ? <span className="badge pass-badge">{mainData?.results?.id_verification_result.id_verified == true ? "PASS" : ""} </span> : ""} </h3>
                        </div>
                      </div>{" "}
                    </div>
                  ) : (
                    ""
                  )} */}

                            {/* <div className="personal-info-area px-4 mb-4" data-aos="fade-left" data-aos-duration="500">
                    <div className="row">
                      <div className="col-md-12">
                        <h3 className="fw-bold pinfo-h3">Personal Info {id_verified == true ? <span className="badge pass-badge">{mainData?.results?.id_verification_result.id_verified == true ? "PASS" : ""} </span> : ""} </h3>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="name-card-main" data-aos="fade-up" data-aos-duration="1500">
                          {mainData && mainData?.enhanced_info?.first_name ? (
                            <div className="name-card">
                              <div className="row">
                                <div className="col-md-10 col-10">
                                  <div className="name-content">
                                    <h5>First Name</h5>
                                    {loaderRes == true ? <h3>{mainData && mainData?.enhanced_info?.first_name ? mainData?.enhanced_info?.first_name : ""} </h3> : <Skeleton count={1} />}
                                  </div>
                                </div>

                                <div className="col-md-2 col-2">
                                  <div className="checkdiv" data-aos="zoom-in">
                                    {first_name_result == true ? mainData?.results?.id_verification_result.first_name_result == "Match" ? <img src={greenCheck} /> : mainData?.results?.id_verification_result.first_name_result == "No Match" ? <img src={checkClose} /> : <img src={minuscheck} /> : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {mainData && mainData?.enhanced_info?.middle_name ? (
                            <div className="name-card">
                              <div className="row">
                                <div className="col-md-10 col-10">
                                  <div className="name-content">
                                    <h5>Middle Name</h5>
                                    {loaderRes == true ? <h3>{mainData && mainData?.enhanced_info?.middle_name ? mainData?.enhanced_info?.middle_name : "-"}</h3> : <Skeleton count={1} />}
                                  </div>
                                </div>

                                <div className="col-md-2 col-2">
                                  <div className="checkdiv" data-aos="zoom-in">
                                    {middle_name_result == true ? mainData?.results?.id_verification_result.middle_name_result == "Match" ? <img src={greenCheck} /> : mainData?.results?.id_verification_result.middle_name_result == "No Match" ? <img src={checkClose} /> : <img src={minuscheck} /> : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {mainData && mainData?.enhanced_info?.last_name ? (
                            <div className="name-card">
                              <div className="row">
                                <div className="col-md-10 col-10">
                                  <div className="name-content">
                                    <h5>Last Name</h5>
                                    {loaderRes == true ? <h3>{mainData && mainData?.enhanced_info?.last_name ? mainData?.enhanced_info?.last_name : ""}</h3> : <Skeleton count={1} />}
                                  </div>
                                </div>

                                <div className="col-md-2 col-2">
                                  <div className="checkdiv" data-aos="zoom-in">
                                    {last_name_result == true ? mainData?.results?.id_verification_result.last_name_result == "Match" ? <img src={greenCheck} /> : mainData?.results?.id_verification_result.last_name_result == "No Match" ? <img src={checkClose} /> : <img src={minuscheck} /> : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="name-card-main">
                          {locationPage && locationPage.length > 0 ? (
                            locationPage[0].city ? (
                              <div className="name-card">
                                <div className="row">
                                  <div className="col-md-10 col-10">
                                    <div className="name-content">
                                      <h5>City</h5>
                                      {loaderRes == true ? <h3>{locationPage && locationPage.length > 0 ? (locationPage[0].city ? locationPage[0].city : "-") : "-"}</h3> : <Skeleton count={1} />}
                                    </div>
                                  </div>

                                  <div className="col-md-2 col-2">
                                    <div className="checkdiv" data-aos="zoom-in">
                                      {city_result == true ? mainData?.results?.id_verification_result.city_result == "Match" ? <img src={greenCheck} /> : mainData?.results?.id_verification_result.city_result == "No Match" ? <img src={checkClose} /> : <img src={minuscheck} /> : ""}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}

                          {locationPage && locationPage.length > 0 ? (
                            locationPage[0].state ? (
                              <div className="name-card">
                                <div className="row">
                                  <div className="col-md-10 col-10">
                                    <div className="name-content">
                                      <h5>State</h5>
                                      {loaderRes == true ? <h3>{locationPage && locationPage.length > 0 ? (locationPage[0].state ? locationPage[0].state : "-") : "-"}</h3> : <Skeleton count={1} />}
                                    </div>
                                  </div>

                                  <div className="col-md-2 col-2">
                                    <div className="checkdiv" data-aos="zoom-in">
                                      {state_result == true ? mainData?.results?.id_verification_result.state_result == "Match" ? <img src={greenCheck} /> : mainData?.results?.id_verification_result.state_result == "No Match" ? <img src={checkClose} /> : <img src={minuscheck} /> : ""}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          {mainData && mainData?.enhanced_info?.date_of_birth || mainData && mainData?.enhanced_info?.age ? (
                            <div className="name-card">
                              <div className="row">
                                <div className="col-md-10 col-10">
                                  <div className="name-content">
                                    <h5>Age</h5>
                                    {loaderRes == true ? <h3>{mainData && mainData?.enhanced_info?.date_of_birth ? calculateAge(mainData?.enhanced_info?.date_of_birth) : mainData && mainData?.enhanced_info?.age ? mainData?.enhanced_info?.age : "" }</h3> : <Skeleton count={1} />}
                                  </div>
                                </div>

                                <div className="col-md-2 col-2">
                                  <div className="checkdiv" data-aos="zoom-in">
                                    {year_of_birth_result == true ? mainData?.results?.id_verification_result?.year_of_birth_result == "Match" ? <img src={greenCheck} /> : mainData?.results?.id_verification_result?.year_of_birth_result == "No Match" ? <img src={checkClose} /> : <img src={minuscheck} /> : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {mainData && mainData?.results?.id_verification_result.marital_status ? (
                            <div className="name-card">
                              <div className="row">
                                <div className="col-md-10 col-10">
                                  <div className="name-content">
                                    <h5>Marriage Status</h5>
                                    {loaderRes == true ? <h3>{mainData?.results?.id_verification_result.marital_status ? mainData?.results?.id_verification_result.marital_status == "No Data" ? "Unknown" : mainData?.results?.id_verification_result.marital_status : "-"}</h3> : <Skeleton count={1} />}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}

                            {/* {loaderRes == true ? (
                    phone_details ? (
                      <div className="personal-info-area px-4 mb-4" data-aos="fade-left" data-aos-duration="500">
                        <div className="row">
                          <div className="col-md-6">
                            <h3 className="fw-bold pinfo-h3">
                              Phone<span className="badge voip-badge"> {mainData?.results && mainData?.results.phone_type ? mainData?.results.phone_type : ""}</span> <span className="badge bg-light text-dark">{mainData && mainData.phone_number ? formatPhoneNumber(mainData.phone_number) : ""}</span>
                            </h3>
                            {logosPhone && logosPhone?.registered_accounts?.length > 0 ? (
    <div className="check-content">
        <p>Social Check </p>
        <h6>{logosPhone?.registered_accounts.length} Verified</h6>
    </div>
) : (
    ""
)}
                          </div>
                          <div className="col-md-6">
                            <div className="risk-score">
                              <h4>
                                <span className="color-orange">{phone_details == true ? mainData?.results?.id_social_result?.phone_details?.risk_score : "0.0"}/ </span> 10
                              </h4>

                              <span>
                                <i className="fa fa-circle"></i>
                              </span>
                              <h6>Risk Score</h6>
                            </div>
                          </div>
                        </div>

                        <div className="name-card-main">
                          <div className="row w-100">
                            {loaderRes == true ? (
                              phone_details == true ? (
                                newMatchPhone &&
                                newMatchPhone.map((account, index) => {
                                  return (
                                    <div className="col-md-3 " key={index}>
                                      <div className="name-card name-card-new" key={account.account}>
                                        <div className="div-social-box">
                                          <img src={account.logos && account.logos ? account.logos?.logoUrl : ""} alt={account.logos?.logoUrl} className="social-icon" /> <br />
                                          <h3>{capitalizeFirstLetter(account.account)}</h3>
                                          {account.accountInfo && account.accountInfo.url && (
                                            <div className="view-profile">
                                              <a href={account.accountInfo && account.accountInfo.url ? account.accountInfo.url : ""} target="_blank">
                                                <span>View Profile </span>
                                                <i className="fa fa-chevron-right"></i>
                                              </a>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                ""
                              )
                            ) : (
                              <Skeleton count={1} />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    <div className="personal-info-area px-4 mb-4" data-aos="fade-left" data-aos-duration="500">
                      <div className="row">
                        <div className="col-md-6">
                          <h3 className="fw-bold pinfo-h3">
                            Phone<span className="badge voip-badge"> {mainData?.results && mainData?.results.phone_type ? mainData?.results.phone_type : ""}</span> <span className="badge bg-light text-dark">{mainData && mainData.phone_number ? formatPhoneNumber(mainData.phone_number) : ""}</span>
                          </h3>
                        </div>
                        <div className="col-md-6">
                          <div className="risk-score">
                            <h4>
                              <span className="color-orange">{phone_details == true ? mainData?.results?.id_social_result?.phone_details?.risk_score : "0.0"}/ </span> 10
                            </h4>

                            <span>
                              <i className="fa fa-circle"></i>
                            </span>
                            <h6>Risk Score</h6>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="name-card-main">
                            <div className="name-card">
                              <div className="div-social-box">
                                <img src={facebook} className="social-icon" /> <br />
                                <h3>
                                  {" "}
                                  <Skeleton count={1} />{" "}
                                </h3>
                                <div className="view-profile">
                                  <a href={logosPhone && logosPhone?.facebook ? logosPhone?.facebook?.url : ""}>
                                    <span>View Profile </span>
                                    <i className="fa fa-chevron-right"></i>
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div className="name-card">
                              <div className="div-social-box">
                                <img src={linkedin} className="social-icon" />
                                <h3>
                                  {" "}
                                  <Skeleton count={1} />{" "}
                                </h3>

                                <div className="view-profile">
                                  <a href="">
                                    <span>View Profile </span>
                                    <i className="fa fa-chevron-right"></i>
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div className="name-card">
                              <div className="div-social-box">
                                <img src={instagram} className="social-icon" />
                                <h3>
                                  {" "}
                                  <Skeleton count={1} />{" "}
                                </h3>

                                <div className="view-profile">
                                  <a href="">
                                    <span>View Profile </span>
                                    <i className="fa fa-chevron-right"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}

                            {/* {email_details == true ? (
                    <div className="personal-info-area px-4 mb-4" data-aos="fade-left" data-aos-duration="500">
                      <div className="row">
                        <div className="col-md-6">
                          <h3 className="fw-bold pinfo-h3">
                            Email<span className="badge bg-light text-dark">{mainData?.results && mainData?.email ? mainData?.email : ""}</span>
                          </h3>
                          {logosEmail && logosEmail?.registered_accounts.length > 0 ? (
                            <div className="check-content">
                              <p>Social Check </p>
                              <h6>{logosEmail && logosEmail?.registered_accounts.length} Verified</h6>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="risk-score">
                            <h4>
                              <span className="color-orange">{email_details == true ? mainData?.results?.id_social_result?.email_details?.risk_score : "0.0"}/</span> 10
                            </h4>
                            <span>
                              <i className="fa fa-circle"></i>
                            </span>
                            <h6>Risk Score</h6>
                          </div>
                        </div>
                      </div>

                      <div className="name-card-main">
                        <div className="row w-100">
                          {loaderRes == true ? (
                            email_details == true ? (
                              newMatch &&
                              newMatch.map((account, accountInfo) => {
                                return (
                                  <>
                                    <div className="col-md-3 " key={account.account}>
                                      <div className="name-card name-card-new" key={account.account}>
                                        <div className="div-social-box">
                                          <img src={account.logos && account.logos ? account.logos?.logoUrl : ""} alt={account.logos?.logoUrl} className="social-icon" /> <br />
                                          <h3>{capitalizeFirstLetter(account.account)}</h3>
                                          {account.accountInfo && account.accountInfo.url && (
                                            <div className="view-profile">
                                              <a href={account.accountInfo && account.accountInfo.url ? account.accountInfo.url : ""} target="_blank">
                                                <span>View Profile </span>
                                                <i className="fa fa-chevron-right"></i>
                                              </a>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              ""
                            )
                          ) : (
                            <Skeleton count={1} />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}

                            {/* {mainData?.results && mainData?.results.crime_search_result.length > 0 ? (
                    <div className="personal-info-area px-4 mb-4" data-aos="fade-up" data-aos-duration="1000">
                      <div className="row">
                        <div className="col-md-12">
                          <h3 className="fw-bold pinfo-h3">
                            Criminal Check <span className="badge voip-badge"> {mainData?.results && mainData?.results.crime_search_result.length} Charges</span>{" "}
                          </h3>
                        </div>
                        {mainData?.results && mainData?.results.crime_search_result.length > 0
                          ? mainData?.results?.crime_search_result.map((i, index) => (
                              <div className="col-md-6" key={index}>
                                <div className="name-card-full">
                                  <div className="name-content">
                                    <h5>Record Name</h5>
                                    <h3>{i.charges && i.charges[0].charge_classification ? i.charges[0]?.charge_classification?.primary_category : ""}</h3>
                                    <hr className="bottom-hr" />
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="name-content pb-4">
                                        <h5>Record Date</h5>
                                        <h3 className="font-size-18 name-card-h3">{i.record_date ? moment(i.record_date).format("MM/DD/YYYY") : i.record_date}</h3>
                                      </div>
                                      <div className="name-content pb-4">
                                        <h5>Charges Filed Date</h5>
                                        <h3 className="font-size-18 name-card-h3">{i.charges ? (i.charges[0].charge_filed_date ? moment(i.charges[0]?.charge_filed_date).format("MM/DD/YYYY") : "") : ""}</h3>
                                      </div>
                                    </div>

                                    <div className="col-lg-6">
                                      <div className="name-content pb-4">
                                        <h5>Case Number</h5>
                                        <h3 className="font-size-18 name-card-h3">{i.case_number}</h3>
                                      </div>

                                      <div className="name-content pb-4">
                                        <h5># of Charges</h5>
                                        <h3 className="font-size-18 name-card-h3">{i.number_of_charges}</h3>
                                      </div>
                                    </div>

                                    <div className="col-lg-12">
                                      <div className="name-content pb-4">
                                        <h5>Charges Description</h5>
                                        <h3 className="font-size-18 name-card-h3 clamp-cont">{i.charges && i.charges[0].offense_description ? i.charges[0]?.offense_description : ""} </h3>
                                      </div>
                                      <div className="view-profile">
                                        <a data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={() => setModelDetails(i)}>
                                          <span>View Full Report </span>
                                          <i className="fa fa-chevron-right" style={{ cursor: "pointer" }}></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          : ""}
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </main>
                <div
                  className="offcanvas offcanvas-end custom-sidebar"
                  tabIndex="-1"
                  id="offcanvasRight"
                  aria-labelledby="offcanvasRightLabel"
                >
                  <div className="offcanvas-header py-4">
                    <h3
                      id="offcanvasRightLabel"
                      className="fw-bold pinfo-h3 mb-0"
                    >
                      Criminal History{" "}
                      <span className="badge voip-badge">
                        {modelDetails && modelDetails.number_of_charges
                          ? modelDetails.number_of_charges
                          : "-"}
                        {modelDetails && modelDetails.number_of_charges > 1
                          ? " Charges"
                          : " Charge"}
                      </span>{" "}
                    </h3>
                    <a
                      type="button"
                      className="pp text-reset fw-600"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      Close
                    </a>
                  </div>
                  <div className="offcanvas-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="name-card-full mb-4">
                          <div className="name-content">
                            <div className="row">
                              <div className="col-md-8">
                                <h5>Record Name</h5>
                                <h3>
                                  {modelDetails && modelDetails.record_county
                                    ? modelDetails.record_county
                                    : "-"}
                                </h3>
                              </div>
                              <div className="col-md-4">
                                <div className="offcanvas-new-button">
                                  <h5>Mug Shot</h5>

                                  <Modal onOpen={showModal}>
                                    <div className="holder">
                                      <a
                                        style={{
                                          cursor:
                                            modelDetails && modelDetails.photo
                                              ? "pointer"
                                              : "text",
                                        }}
                                      >
                                        {" "}
                                        {modelDetails && modelDetails.photo
                                          ? "View Photo"
                                          : "No Photo"}{" "}
                                        {modelDetails && modelDetails.photo ? (
                                          <i className="fa fa-chevron-right"></i>
                                        ) : (
                                          ""
                                        )}
                                      </a>
                                    </div>
                                  </Modal>

                                  {isOpen && (
                                    <ModalContent
                                      onClose={() => setIsopen(false)}
                                    >
                                      <img
                                        style={{
                                          cursor: "pointer",
                                          maxWidth: "50%",
                                          height: "90%",
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                        }}
                                        src={
                                          modelDetails && modelDetails.photo
                                            ? modelDetails.photo
                                            : ""
                                        }
                                        alt=""
                                      />
                                    </ModalContent>
                                  )}
                                </div>
                              </div>
                            </div>

                            <hr className="bottom-hr" />
                          </div>

                          <div className="row">
                            <div className="col-lg-4">
                              <div className="name-content pb-4">
                                <h5>First Name</h5>
                                <h3 className="font-size-18 name-card-h3">
                                  {modelDetails && modelDetails.first_name
                                    ? modelDetails.first_name
                                    : "-"}
                                </h3>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="name-content pb-4">
                                <h5>Middle Name</h5>
                                <h3 className="font-size-18 name-card-h3">
                                  {" "}
                                  {modelDetails && modelDetails.middle_name
                                    ? modelDetails.middle_name
                                    : "-"}
                                </h3>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="name-content pb-4">
                                <h5>Last Name</h5>
                                <h3 className="font-size-18 name-card-h3">
                                  {modelDetails && modelDetails.last_name
                                    ? modelDetails.last_name
                                    : "-"}
                                </h3>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="name-content pb-4">
                                <h5>AKA</h5>
                                <h3 className="font-size-18 name-card-h3">
                                  {modelDetails && modelDetails.aka
                                    ? modelDetails.aka
                                    : "-"}
                                </h3>
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div className="name-content pb-4">
                                <h5>DOB</h5>
                                <h3 className="font-size-18 name-card-h3">
                                  {" "}
                                  {modelDetails && modelDetails.dob
                                    ? moment(modelDetails.dob).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "-"}
                                </h3>
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div className="name-content pb-4">
                                <h5>Age</h5>
                                <h3 className="font-size-18 name-card-h3">
                                  {modelDetails && modelDetails.age_result
                                    ? modelDetails.age
                                    : "-"}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        {modelDetails && modelDetails ? (
                          <div className="name-card-full mb-4">
                            <div className="row">
                              {modelDetails && modelDetails.record_state ? (
                                <div className="col-lg-4">
                                  <div className="name-content pb-4">
                                    <h5>Record State</h5>
                                    <h3 className="font-size-18 name-card-h3">
                                      {modelDetails && modelDetails.record_state
                                        ? modelDetails.record_state
                                        : "-"}
                                    </h3>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              {modelDetails && modelDetails.record_county ? (
                                <div className="col-lg-4">
                                  <div className="name-content pb-4">
                                    <h5>Record County</h5>
                                    <h3 className="font-size-18 name-card-h3">
                                      {modelDetails &&
                                      modelDetails.record_county
                                        ? modelDetails.record_county
                                        : "-"}
                                    </h3>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              {modelDetails && modelDetails.record_date ? (
                                <div className="col-lg-4">
                                  <div className="name-content pb-4">
                                    <h5>Record Date</h5>
                                    <h3 className="font-size-18 name-card-h3">
                                      {modelDetails && modelDetails.record_date
                                        ? moment(
                                            modelDetails.record_date
                                          ).format("MM/DD/YYYY")
                                        : "-"}
                                    </h3>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              {modelDetails &&
                              modelDetails.number_of_charges ? (
                                <div className="col-lg-4">
                                  <div className="name-content pb-4">
                                    <h5># of Charges</h5>
                                    <h3 className="font-size-18 name-card-h3">
                                      {modelDetails &&
                                      modelDetails.number_of_charges
                                        ? modelDetails.number_of_charges
                                        : "-"}
                                    </h3>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className=" col-md-12">
                        {modelDetails && modelDetails.offenses?.length > 0 ? (
                          <div className="name-card-full mb-4">
                            {modelDetails.offenses.map((i, index) => (
                              
                              <div className="row" key={index}>
                                {i.charge_filed_date ? (
                                  <div className="col-lg-4">
                                    <div className="name-content pb-4">
                                      <h5>Charge Filed Date</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {moment(i.charge_filed_date).format(
                                          "MM/DD/YYYY"
                                        )}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-lg-4">
                                    <div className="name-content pb-4">
                                      <h5>Charge Filed Date</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        -
                                      </h3>
                                    </div>
                                  </div>
                                )}
                                {i.offense_date ? (
                                  <div className="col-lg-4">
                                    <div className="name-content pb-4">
                                      <h5>Offense Date</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {i.offense_date
                                          ? moment(i.offense_date).format(
                                              "MM/DD/YYYY"
                                            )
                                          : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {i.offense_code ? (
                                  <div className="col-lg-4">
                                    <div className="name-content ">
                                      <h5>Offense Code</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {i.offense_code ? i.offense_code?.replace("0794.0412.B - NOT PROVIDED BY SOURCE, ", "")
    .replace("0794.0412.B - NOT PROVIDED BY SOURCE", "")
    .replace("NOT PROVIDED BY SOURCE, ", "")
    .replace("NOT PROVIDED BY SOURCE", "") : "-"}
                                      </h3>
                                    </div>
                                    <div className="col-lg-12">
                                      {i.offense_description ? (
                                        <div className="name-content pb-4 pt-0">
                                          <hr className="top-hr" />
                                          <h5>Offense Description</h5>
                                          <h3 className="font-size-18 name-card-h3">
                                            {i.offense_description
                                              ? i.offense_description?.replace("0794.0412.B - NOT PROVIDED BY SOURCE, ", "")
                                              .replace("0794.0412.B - NOT PROVIDED BY SOURCE", "")
                                              .replace("NOT PROVIDED BY SOURCE, ", "")
                                              .replace("NOT PROVIDED BY SOURCE", "")
                                              : "-"}{" "}
                                          </h3>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {/* Add other fields in similar divs here if required */}
                              </div>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-12">
                        {modelDetails && modelDetails.offenses?.length > 0 ? (
                          <div className="name-card-full mb-4">
                            {modelDetails.offenses.map((i) => (
                              <div className="row">
                                {i.charge_count ? (
                                  <div className="col-lg-4">
                                    <div className="name-content pb-4">
                                      <h5>Charge Count</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {i.charge_count ? i.charge_count : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {i.plea ? (
                                  <div className="col-lg-4">
                                    <div className="name-content pb-4">
                                      <h5>Plea</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {i.plea ? i.plea : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {i.conviction_date ? (
                                  <div className="col-lg-4">
                                    <div className="name-content pb-4">
                                      <h5>Conviction Date</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {i.conviction_date
                                          ? moment(i.conviction_date).format(
                                              "MM/DD/YYYY"
                                            )
                                          : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {i.court ? (
                                  <div className="col-lg-6">
                                    <div className="name-content ">
                                      <h5>Court</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {i.court ? i.court : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {i.conviction_place ? (
                                  <div className="col-lg-6">
                                    <div className="name-content pb-4">
                                      <h5>Conviction Place</h5>
                                      <h3 className="font-s ize-18 name-card-h3">
                                        {i.conviction_place
                                          ? i.conviction_place
                                          : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {i.sentence_length ? (
                                  <div className="col-lg-6">
                                    <div className="name-content pb-4">
                                      <h5>Sentence Length</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {i.sentence_length
                                          ? i.sentence_length
                                          : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {i.probation_length ? (
                                  <div className="col-lg-6">
                                    <div className="name-content pb-4">
                                      <h5>Probation</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {i.probation_length
                                          ? i.probation_length
                                          : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {i.disposition ? (
                                  <div className="col-lg-12">
                                    <div className="name-content pb-4 pt-0">
                                      <h5>Disposition</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {i.disposition ? i.disposition : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {i.Disposition_Date ? (
                                  <div className="col-lg-4">
                                    <hr className="top-hr" />
                                    <div className="name-content pb-4">
                                      <h5>Disposition_Date</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {i.Disposition_Date
                                          ? moment(i.Disposition_Date).format(
                                              "MM/DD/YYYY"
                                            )
                                          : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {i.Arrest_Date ? (
                                  <div className="col-lg-4">
                                    <div className="name-content pb-4">
                                      <h5>Arrest_Date</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {i.Arrest_Date
                                          ? moment(i.Arrest_Date).format(
                                              "MM/DD/YYYY"
                                            )
                                          : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {i.Release_Date ? (
                                  <div className="col-lg-4">
                                    <div className="name-content pb-4">
                                      <h5>Parole_Date</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {i.Parole_Date
                                          ? moment(i.Parole_Date).format(
                                              "MM/DD/YYYY"
                                            )
                                          : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {i.Release_Date ? (
                                  <div className="col-lg-4">
                                    <div className="name-content pb-4">
                                      <h5>Release_Date</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {i.Release_Date
                                          ? moment(i.Release_Date).format(
                                              "MM/DD/YYYY"
                                            )
                                          : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {i.Admitted_Date ? (
                                  <div className="col-lg-4">
                                    <div className="name-content pb-4">
                                      <h5>Admitted_Date</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {i.Admitted_Date
                                          ? moment(i.Admitted_Date).format(
                                              "MM/DD/YYYY"
                                            )
                                          : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {i.Disposition_Date ? (
                                  <div className="col-lg-4">
                                    <div className="name-content pb-4">
                                      <h5>Case_Number</h5>
                                      <h3 className="font-size-18 name-card-h3">
                                        {i.Case_Number ? i.Case_Number : "-"}
                                      </h3>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          </>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full viewport height
            width: "100%", // Full width
            position: "absolute", // Overlaying the entire page if needed
          }}
        >
          {loading ? <Spinner /> : ""}
        </div>
      )}
      {!loading ? (
        Version &&
        Version == "Gen1" && (
          <React.Fragment>
            <div className="page-content">
              <MetaTags>
                <title> Search Result Details | BrightCheck</title>
              </MetaTags>
              <Container fluid>
                {/* Render Breadcrumb */}
                {/* <Breadcrumbs
            title="Dashboards"
            breadcrumbItem="Order"
          /> */}
                <div
                  className="d-block py-2 pb-3"
                  style={{ zIndex: 10, position: "relative" }}
                >
                  <Link
                    to="/search-results"
                    className="text-gray text-hover-gray"
                  >
                    <i className="bx bx-arrow-back"></i> Back to Search Results
                  </Link>
                </div>
              </Container>
              <Container fluid>
                <Row>
                  <Col lg={9}>
                    <h5 className="text-gray">Results Returned</h5>
                    <div className="result-accordion">
                      <Accordion open={open} toggle={toggle}>
                        {/* Anti Cash Fish  */}

                        {orderData?.order?.map((w, i) => {
                          if (
                            w?.service_name
                              ?.toLowerCase()
                              .includes("anti-catfish")
                          ) {
                            return (
                              <AccordionItem>
                                <AccordionHeader targetId={`${i + 1}`}>
                                  Anti Catfish Check
                                </AccordionHeader>
                                {/* {w?.result.anti_catfish && (  */}

                                <AccordionBody accordionId={`${i + 1}`}>
                                  <Row className="border-bottom-gray2 pb-2 mb-2">
                                    <Col sm={6}>
                                      <p className="text-gray mb-2">
                                        First Name
                                      </p>
                                      <h5 className="text-gray title-width d-flex align-items-center">
                                        {/* <img src={UnknownIcon} alt="close dark icon" className="w-5" /> */}
                                        <b>{orderData?.first_name}</b>{" "}
                                        <span
                                          className={`bg-${
                                            w?.result?.anti_catfish
                                              ?.first_name == "match"
                                              ? "success"
                                              : w?.result?.anti_catfish
                                                  ?.first_name == "partial"
                                              ? "partial"
                                              : "unknown"
                                          } badge bg-secondary ms-3`}
                                        >
                                          {w?.result?.anti_catfish
                                            ?.first_name == "match" ? (
                                            <i className="bx bxs-check-circle svg-black me-1"></i>
                                          ) : w?.result?.anti_catfish
                                              ?.first_name == "partial" ? (
                                            <img
                                              src={PartialIcon}
                                              alt="close dark icon"
                                              className="w-1"
                                              style={{
                                                paddingRight: "7px",
                                                paddingBottom: "2px",
                                                width: "22px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={UnknownIcon}
                                              alt="close dark icon"
                                              className="w-5"
                                              style={{
                                                paddingRight: "7px",
                                                width: "22px",
                                              }}
                                            />
                                          )}{" "}
                                          {/* {Object.keys(w?.result.anti_catfish?.first_name).map(([key, value]) => {

                                      {
                                        value == "match"
                                        ? "Verified"

                                        : value == "partial" ? "partial" : "Not Verified"
                                      }
                                    })} */}
                                          {w?.result?.anti_catfish
                                            ?.first_name == "match"
                                            ? "Verified"
                                            : w?.result?.anti_catfish
                                                ?.first_name == "partial"
                                            ? "Partial"
                                            : "Not Verified"}
                                        </span>
                                      </h5>
                                    </Col>
                                    <Col sm={6}>
                                      <p className="text-gray mb-2">
                                        Last Name
                                      </p>
                                      <h5 className="text-gray title-width d-flex align-items-center">
                                        <b>{orderData?.last_name}</b>
                                        <span
                                          className={`bg-${
                                            w?.result?.anti_catfish
                                              ?.last_name == "match"
                                              ? "success"
                                              : w?.result?.anti_catfish
                                                  ?.last_name == "partial"
                                              ? "partial"
                                              : "unknown"
                                          } badge bg-secondary ms-3`}
                                        >
                                          {w?.result?.anti_catfish?.last_name ==
                                          "match" ? (
                                            <i className="bx bxs-check-circle svg-black me-1"></i>
                                          ) : w?.result?.anti_catfish
                                              ?.last_name == "partial" ? (
                                            <img
                                              src={PartialIcon}
                                              alt="close dark icon"
                                              className="w-5"
                                              style={{
                                                paddingRight: "7px",
                                                paddingBottom: "2px",
                                                width: "22px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={UnknownIcon}
                                              alt="close dark icon"
                                              className="w-5"
                                              style={{
                                                paddingRight: "7px",
                                                width: "22px",
                                              }}
                                            />
                                          )}
                                          {w?.result?.anti_catfish?.last_name ==
                                          "match"
                                            ? "Verified"
                                            : w?.result?.anti_catfish
                                                ?.last_name == "partial"
                                            ? "Partial"
                                            : "Not Verified"}
                                        </span>
                                      </h5>
                                    </Col>
                                  </Row>
                                  <Row className="border-bottom-gray2 pb-2 mb-2">
                                    <Col sm={6}>
                                      <p className="text-gray mb-2">State</p>
                                      <h5 className="text-gray title-width d-flex align-items-center">
                                        <b>{orderData?.state}</b>
                                        <span
                                          className={`bg-${
                                            w?.result?.anti_catfish?.state ==
                                            "match"
                                              ? "success"
                                              : w?.result?.anti_catfish
                                                  ?.state == "partial"
                                              ? "partial"
                                              : "unknown"
                                          } badge bg-secondary ms-3`}
                                        >
                                          {w?.result?.anti_catfish?.state ==
                                          "match" ? (
                                            <i className="bx bxs-check-circle svg-black me-1"></i>
                                          ) : w?.result?.anti_catfish?.state ==
                                            "partial" ? (
                                            <img
                                              src={PartialIcon}
                                              alt="close dark icon"
                                              className="w-5"
                                              style={{
                                                paddingRight: "7px",
                                                paddingBottom: "2px",
                                                width: "22px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={UnknownIcon}
                                              alt="close dark icon"
                                              className="w-5"
                                              style={{
                                                paddingRight: "7px",
                                                width: "22px",
                                              }}
                                            />
                                          )}
                                          {w?.result?.anti_catfish?.state ==
                                          "match"
                                            ? "Verified"
                                            : w?.result?.anti_catfish?.state ==
                                              "partial"
                                            ? "Partial"
                                            : "Not Verified"}
                                        </span>
                                      </h5>
                                    </Col>
                                    <Col sm={6}>
                                      <p className="text-gray mb-2">City</p>
                                      <h5 className="text-gray title-width d-flex align-items-center">
                                        <b>{orderData?.city}</b>
                                        <span
                                          className={`bg-${
                                            w?.result?.anti_catfish?.city ==
                                            "match"
                                              ? "success"
                                              : w?.result?.anti_catfish?.city ==
                                                "partial"
                                              ? "partial"
                                              : "unknown"
                                          } badge bg-secondary ms-3`}
                                        >
                                          {w?.result?.anti_catfish?.city ==
                                          "match" ? (
                                            <i className="bx bxs-check-circle svg-black me-1"></i>
                                          ) : w?.result?.anti_catfish?.city ==
                                            "partial" ? (
                                            <img
                                              src={PartialIcon}
                                              alt="close dark icon"
                                              className="w-5"
                                              style={{
                                                paddingRight: "7px",
                                                paddingBottom: "2px",
                                                width: "22px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={UnknownIcon}
                                              alt="close dark icon"
                                              className="w-5"
                                              style={{
                                                paddingRight: "7px",
                                                width: "22px",
                                              }}
                                            />
                                          )}
                                          {w?.result?.anti_catfish?.city ==
                                          "match"
                                            ? "Verified"
                                            : w?.result?.anti_catfish?.city ==
                                              "partial"
                                            ? "Partial"
                                            : "Not Verified"}
                                        </span>
                                      </h5>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <p className="text-gray mb-2">Age</p>
                                      <h5 className="text-gray title-width d-flex align-items-center">
                                        <b>{orderData?.age}</b>
                                        <span
                                          className={`bg-${
                                            w?.result?.anti_catfish?.yob ==
                                            "match"
                                              ? "success"
                                              : w?.result?.anti_catfish?.yob ==
                                                "partial"
                                              ? "partial"
                                              : "unknown"
                                          } badge bg-secondary ms-3`}
                                        >
                                          {w?.result?.anti_catfish?.yob ==
                                          "match" ? (
                                            <i className="bx bxs-check-circle svg-black me-1"></i>
                                          ) : w?.result?.anti_catfish?.yob ==
                                            "partial" ? (
                                            <img
                                              src={PartialIcon}
                                              alt="close dark icon"
                                              className="w-5"
                                              style={{
                                                paddingRight: "7px",
                                                paddingBottom: "2px",
                                                width: "22px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={UnknownIcon}
                                              alt="close dark icon"
                                              className="w-5"
                                              style={{
                                                paddingRight: "7px",
                                                width: "22px",
                                              }}
                                            />
                                          )}
                                          {w?.result?.anti_catfish?.yob ==
                                          "match"
                                            ? "Verified"
                                            : w?.result?.anti_catfish?.yob ==
                                              "partial"
                                            ? "Partial"
                                            : "Not Verified"}
                                        </span>
                                      </h5>
                                    </Col>
                                    <Col sm={6}>
                                      <p className="text-gray mb-2">
                                        Married Status
                                      </p>
                                      <h5 className="text-gray title-width d-flex align-items-center">
                                        {/* {w?.result?.anti_catfish?.marital_status ==
                                  "match" ? (
                                    <b>Married</b>
                                  ) : w?.result?.anti_catfish?.marital_status ==
                                    "partial" ? (
                                    <b>Single</b>
                                  ) : (
                                    <b>Single</b>
                                  )} */}
                                        {/* <b>Married</b> */}
                                        <span
                                          className={`bg-${
                                            w?.result?.anti_catfish
                                              ?.marital_status === "match"
                                              ? "danger"
                                              : w?.result?.anti_catfish
                                                  ?.marital_status ===
                                                "no_match"
                                              ? "success"
                                              : "warning"
                                          } badge ms-3`}
                                        >
                                          {w?.result?.anti_catfish
                                            ?.marital_status === "match" ? (
                                            <i className="bx bxs-x-circle svg-black me-1"></i>
                                          ) : w?.result?.anti_catfish
                                              ?.marital_status ===
                                            "no_match" ? (
                                            <i className="bx bxs-check-circle svg-black me-1"></i>
                                          ) : null}
                                          {w?.result?.anti_catfish
                                            ?.marital_status === "match"
                                            ? "Married"
                                            : w?.result?.anti_catfish
                                                ?.marital_status === "no_match"
                                            ? "Single"
                                            : "Unknown"}
                                        </span>
                                      </h5>
                                    </Col>
                                  </Row>
                                </AccordionBody>

                                {/* ) } */}
                              </AccordionItem>
                            );
                          } else if (
                            w?.service_name?.toLowerCase().includes("criminal")
                          ) {
                            return (
                              <AccordionItem>
                                <AccordionHeader targetId={`${i + 1}`}>
                                  Criminal Check
                                </AccordionHeader>

                                <AccordionBody accordionId={`${i + 1}`}>
                                  <Row className="border-bottom-gray2 pb-2 mb-2">
                                    <Col sm={6}>
                                      <p className="text-gray mb-2"> Name</p>
                                      <h5 className="text-gray">
                                        <b>
                                          {
                                            w?.result?.criminal[0]
                                              ?.DefendantName
                                          }{" "}
                                        </b>
                                      </h5>
                                    </Col>
                                    <Col sm={6}>
                                      <p className="text-gray mb-2">Age</p>
                                      <h5 className="text-gray">
                                        <b>{w?.result?.criminal[0]?.DOB} </b>
                                      </h5>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <p className="text-gray mb-2">State</p>
                                      <h5 className="text-gray">
                                        <b>{w?.result?.criminal[0]?.State} </b>
                                      </h5>
                                    </Col>
                                    <Col sm={6}></Col>
                                  </Row>
                                </AccordionBody>

                                {w?.result?.criminal?.map((r) => {
                                  return (
                                    <AccordionBody accordionId={`${i + 1}`}>
                                      <Row className="border-bottom-gray2 pb-2 mb-2">
                                        <Col sm={6}>
                                          <div className="d-flex align-items-center">
                                            <p className="text-gray mb-2">
                                              Criminal Record
                                            </p>
                                            <h5 className="text-gray">
                                              <span
                                                className={`bg-${
                                                  r?.OffenseType === ""
                                                    ? "success"
                                                    : r?.OffenseType ===
                                                      "Sex Offender"
                                                    ? "unknown"
                                                    : "warning"
                                                } badge ms-3`}
                                              >
                                                {r?.OffenseType ===
                                                "" ? null : (
                                                  <i className="bx bxs-error svg-black me-1"></i>
                                                )}
                                                {r?.OffenseType === ""
                                                  ? "Unknown"
                                                  : r?.OffenseType}
                                              </span>
                                            </h5>
                                          </div>
                                        </Col>
                                        <Col sm={6}>
                                          <p className="text-gray mb-2">
                                            File Number
                                          </p>
                                          <h5 className="text-gray">
                                            <b>{r?.FileNumber} </b>
                                          </h5>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm={6}>
                                          <p className="text-gray mb-2">
                                            Court Room
                                          </p>
                                          <h5 className="text-gray">
                                            <b>{r?.CourtRoom} </b>
                                          </h5>
                                        </Col>
                                        <Col sm={6}>
                                          <p className="text-gray mb-2">
                                            Defendant Name
                                          </p>
                                          <h5 className="text-gray">
                                            <b>{r?.DefendantName} </b>
                                          </h5>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm={6}>
                                          <p className="text-gray mb-2">
                                            Offense Description
                                          </p>
                                          <h5 className="text-gray">
                                            <b>{r?.OffenseDescription} </b>
                                          </h5>
                                        </Col>
                                        <Col sm={6}>
                                          <p className="text-gray mb-2">
                                            Offense Number
                                          </p>
                                          <h5 className="text-gray">
                                            <b>{r?.OffenseNumber} </b>
                                          </h5>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm={6}>
                                          <p className="text-gray mb-2">
                                            Offense Type
                                          </p>
                                          <h5 className="text-gray">
                                            <b>{r?.OffenseType} </b>
                                          </h5>
                                        </Col>
                                        <Col sm={6}>
                                          <p className="text-gray mb-2">
                                            Source Name
                                          </p>
                                          <h5 className="text-gray">
                                            <b>{r?.SourceName} </b>
                                          </h5>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm={6}>
                                          <p className="text-gray mb-2">
                                            Record Date
                                          </p>
                                          <h5 className="text-gray">
                                            <b>
                                              {r?.Record_date !== ""
                                                ? r?.Record_date
                                                : ""}{" "}
                                            </b>
                                          </h5>
                                        </Col>
                                      </Row>
                                    </AccordionBody>
                                  );
                                })}
                              </AccordionItem>
                            );
                          } else if (
                            w?.service_name?.toLowerCase().includes("social")
                          ) {
                            return (
                              <AccordionItem>
                                <AccordionHeader targetId={`${i + 1}`}>
                                  Social Check
                                </AccordionHeader>
                                <AccordionBody accordionId={`${i + 1}`}>
                                  {w?.result.social?.email && (
                                    <>
                                      <h5 className="text-gray title-width mb-3 d-flex align-items-center">
                                        <b>Email :- </b>
                                        <span className="ms-3">
                                          <strong>
                                            {w?.result.social?.email}
                                          </strong>
                                        </span>
                                      </h5>
                                      <ul className="social-check-email-list">
                                        {Object.keys(
                                          w?.result.social?.email_data
                                        ).length > 0 &&
                                          Object.entries(
                                            w?.result.social?.email_data
                                          ).map(([key, value]) => {
                                            return (
                                              <li key={key}>
                                                <img
                                                  width={25}
                                                  height={25}
                                                  src={
                                                    require(`../../assets/icons/social/${key}.png`)
                                                      .default
                                                  }
                                                  alt={`${key}-logo`}
                                                />
                                                <p>{key}</p>
                                                <span
                                                  className={`bg-${
                                                    value
                                                      ? "success"
                                                      : "unknown"
                                                  } badge bg-secondary ms-3`}
                                                >
                                                  {value ? (
                                                    <i className="bx bxs-check-circle svg-black me-1"></i>
                                                  ) : (
                                                    <img
                                                      src={UnknownIcon}
                                                      alt="close dark icon"
                                                      className="w-5"
                                                      style={{
                                                        paddingRight: "7px",
                                                        width: "22px",
                                                      }}
                                                    />
                                                  )}{" "}
                                                  {value
                                                    ? "Verified"
                                                    : "Unknown"}
                                                </span>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </>
                                  )}
                                  {w?.result.social?.phone && (
                                    <>
                                      <h5 className="text-gray title-width mt-5 mb-3  d-flex align-items-center">
                                        <b>Phone No. :- </b>
                                        <span className="ms-3">
                                          <strong>
                                            {w?.result.social?.phone}
                                          </strong>
                                        </span>
                                      </h5>
                                      <ul className="social-check-phone-list">
                                        {Object.keys(
                                          w?.result.social?.phone_data
                                        ).length > 0 &&
                                          Object.entries(
                                            w?.result.social?.phone_data
                                          ).map(([key, value]) => {
                                            var src = "";

                                            try {
                                              src =
                                                require(`../../assets/icons/social/${key}.png`).default;
                                            } catch (err) {}

                                            return (
                                              <li key={key}>
                                                <img
                                                  width={25}
                                                  height={25}
                                                  src={src}
                                                  alt={`${src}-logo`}
                                                />
                                                <p>{key}</p>
                                                <span
                                                  className={`bg-${
                                                    value
                                                      ? "success"
                                                      : "unknown"
                                                  } badge bg-secondary ms-3`}
                                                >
                                                  {value ? (
                                                    <i className="bx bxs-check-circle svg-black me-1"></i>
                                                  ) : (
                                                    <img
                                                      src={UnknownIcon}
                                                      alt="close dark icon"
                                                      className="w-5"
                                                      style={{
                                                        paddingRight: "7px",
                                                        width: "22px",
                                                      }}
                                                    />
                                                  )}{" "}
                                                  {value
                                                    ? "Verified"
                                                    : "Unknown"}
                                                </span>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </>
                                  )}
                                </AccordionBody>
                              </AccordionItem>
                            );
                          }
                        })}
                      </Accordion>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <Card className="theme-boxed bg-primary">
                      <CardBody>
                        <Card className="mb-0">
                          <CardBody>
                            <h5 className="text-gray">TIMES SEARCHED</h5>
                            <h2 className="mb-4">
                              {orderData.timesearch || 1}
                            </h2>
                            <h5 className="text-gray">LAST CHECKED ON</h5>
                            <p className="mb-0 text-gray">
                              {" "}
                              {moment(new Date(orderData.createdAt)).format(
                                "DD / MM / YYYY"
                              )}
                            </p>
                            <p>
                              {" "}
                              {moment(new Date(orderData.createdAt)).format(
                                " h:mm:ss a"
                              )}
                            </p>
                          </CardBody>
                        </Card>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </React.Fragment>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full viewport height
            width: "100%", // Full width
            position: "absolute", // Overlaying the entire page if needed
          }}
        >
          {loading ? <Spinner /> : ""}
        </div>
      )}
    </>
  );
};

export default SearchResultDetails;
